import { reactive, onMounted, nextTick, watch, toRaw } from 'vue';
import { cloneDeep, debounce } from 'lodash';
interface PagerParams {
  page:number,
  pagesize:number
}
/**
 * 页面常用的page方法 换页 排序 初始请求等等
 *
 * @param { Function } getDaTa - 请求数据的函数
 * @param { Object } params - 请求需要监听的参数
 * @param { Boolean } isWatch - 是否监听参数
 * @param { Boolean } isInit - 是否执行初始请求
 * */
export const useQuery = <T>(getDaTa:() => void, params?:T, isWatch:boolean = true, isInit:Boolean = true) => {
  const initState = {
    pagerConfig: {
      page: 1,
      pagesize: 20,
      total: 1000
    },
    loading: false,
    sort: {
      order: 'descending',
      prop: 'id'
    }
  };
  const state = reactive(initState);
  let initParams:T = null;
  onMounted(() => {
    if (params && isWatch) {
      initParams = cloneDeep(toRaw(params));
    }
    nextTick(() => {
      if (isInit) getDaTa();
    });
  });
  // 监听参数变化
  watch(() => params, val => {
    debounceGetDate();
  }, { deep: true });
  // 防抖函数
  const debounceGetDate = debounce(getDaTa, 500);
  /* 切换分页 */
  const handlePaperChange = (val:PagerParams) => {
    if (val.pagesize) {
      state.pagerConfig.pagesize = val.pagesize;
    }
    if (val.page) {
      state.pagerConfig.page = val.page;
    }
    getDaTa();
  };
  /** 排序 */
  const handleSortChange = (val:any) => {
    // TODO:排序处理相关数据
    getDaTa();
  };
  /** 重置内部 */
  const resetInnerState = () => {
    Object.assign(state, initState);
  };
  /** 重置外部 */
  const resetOuterState = () => {
    Object.assign(params, initParams);
  };
  /** 重置全部 */
  const resetState = () => {
    resetInnerState();
    resetOuterState();
  };
  return {
    state,
    initParams,
    resetInnerState,
    resetOuterState,
    resetState,
    handlePaperChange,
    handleSortChange,
    debounceGetDate
  };
};
