<template>
  <div
    :id="'aceEditor-' + rid"
    style="height: 400px; border-radius: 4px;"
    class="ace-editor">
  </div>
</template>

<script lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, defineComponent } from 'vue';
import ace from 'ace-builds';
// 根据自己的需求按需引入
import 'ace-builds/src-noconflict/theme-vibrant_ink'; // 主题
import 'ace-builds/src-noconflict/mode-velocity'; // 语言模式
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/velocity';

export default defineComponent({
  name: 'GjAceEditor',
  props: {
    // 外部传入的内容，用于实现双向绑定
    rid: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const editor = ref();

    const initialize = () => {
      if (editor.value) {
        // 实例销毁
        editor.value.destroy();
      }
      // 编辑器初始化
      editor.value = ace.edit(`aceEditor-${props.rid}`, {
        theme: 'ace/theme/vibrant_ink',
        mode: 'ace/mode/velocity',
        fontSize: 14,
        // 只读
        readOnly: props.readonly,
        // 启用换行
        wrap: true,
        // 显示参考线
        displayIndentGuides: false
      });

      editor.value.setOptions({
      // 启用代码段
        enableSnippets: true,
        // 启用实时自动完成
        enableLiveAutocompletion: true,
        // 启用基本自动完成
        enableBasicAutocompletion: true
      });

      // 支持双向绑定
      editor.value.on('change', () => {
        if (emit) {
          emit('update:modelValue', editor.value.getValue());
        }
      });

      editor.value.setValue(props.modelValue ? props.modelValue : '');
    };
    watch(
      () => props.modelValue,
      (newProps) => {
        // 解决光标移动问题
        const position = editor.value.getCursorPosition();
        editor.value.getSession().setValue(newProps);
        editor.value.clearSelection();
        editor.value.moveCursorToPosition(position);
      }
    );

    onMounted(() => {
      initialize();
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });
  }
});
</script>

<style lang="scss" scoped>
// 设置编辑器的滚动条样式
:deep(.ace_scrollbar)::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
:deep(.ace_scrollbar)::-webkit-scrollbar-track {
  background: #0F0F0F;
}
:deep(.ace_scrollbar)::-webkit-scrollbar-thumb:hover {
  background-color: #6b6b6b;
}
:deep(.ace_scrollbar)::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: var(--border-radius-4);
  cursor: pointer;
}
</style>
