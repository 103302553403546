<template>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <Toolbar
        :editor="editorRef"
        :default-config="toolbarConfig"
        :mode="mode"
        style="border-bottom: 1px solid #ccc"></Toolbar>
      <Editor
        v-loading="loading"
        v-model="valueHtml"
        :value="modelValue"
        :default-config="editorConfig"
        :mode="mode"
        placeholder="请输入内容"
        :style="{'height': height+'px', 'overflowY': 'hidden'}"
        @onCreated="handleCreated"
        @onChange="handleChange"
        @onDestroyed="handleDestroyed"
        @onFocus="handleFocus"
        @onBlur="handleBlur"
        @customAlert="customAlert"
        @customPaste="customPaste"></Editor>
    </div>
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css';
import { defineComponent, onBeforeUnmount, ref, shallowRef, nextTick } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { DomEditor } from '@wangeditor/editor'
import { getXToen } from '@/utils/auth';
import { BaseService } from '@/api/base/index';
import { postformProgress } from '@/api/http';
import useGlobal from '@/common/useGlobal';

export default defineComponent({
  name: 'GjRichTextEditor',
  components: { Editor, Toolbar },
  props: {
    defaultHtml: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '500'
    },
    modelValue: {
      type: String,
      default: '<p></p>'
    },
    disable: {
      type: Boolean,
      default: false
    },
    excludeKeys: {
      type: Array,
      default: () => [
        "emotion",
        "insertTable",
        "codeBlock",
        "divider"
      ]
    }
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  setup (props, { emit }) {
    const { $gjMessage } = useGlobal();
    // 编辑器实例，必须用 shallowRef，重要！
    const editorRef = shallowRef();

    if (props.disable) {
      nextTick(() => {
        editorRef.value.disable();
      });
    }

    // 内容 HTML
    const valueHtml = ref(props.modelValue); 

    // setTimeout(()=>{
    //   // 工具栏的key
    //   console.log(DomEditor.getToolbar(editorRef.value).config.toolbarKeys)
    // }, 3000)   
    const toolbarConfig = {
      excludeKeys: props.excludeKeys
    };

    const editorConfig = {
      placeholder: '请输入内容',
      MENU_CONF: { uploadImage: {}, uploadVideo: {} }
    };

    const loading = ref(false);
    editorConfig.MENU_CONF.uploadImage = {     
      // 自定义上传
      async customUpload (file, insertFn) {
        loading.value = true;
        const formData = new FormData();
        formData.append('file', file);
        postformProgress('/v2/file/uploadFile', formData, () => {}).then(res => {
          if (res?.data?.code === 0) {
            loading.value = false;
            // 最后插入图片
            // insertFn(url, alt, href)
            insertFn(res?.data?.data?.fileUrl, '', res?.data?.data?.fileUrl);
          } else {
            loading.value = false;
            $gjMessage.warning('图片上传失败');
          }
        });
      }
    };

    
    editorConfig.MENU_CONF.uploadVideo = {      
      // 自定义插入视频
      async customUpload (file, insertFn) {
        // debugger
        const formData = new FormData();
        formData.append('file', file);
        loading.value = true;
        postformProgress('/v2/file/uploadFile', formData, () => {}).then(res => {
          if (res?.data?.code === 0) {
            // 从 res 中找到 url poster ，然后插入视频
            // insertFn(url, poster)            
            loading.value = false;
            insertFn(res?.data?.data?.fileUrl);
          } else {            
            loading.value = false;
            $gjMessage.warning('视频上传失败');
          }
        });

      }
    };

    // 组件销毁时，也及时销毁编辑器，重要！
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;

      editor.destroy();
    });

    // 编辑器回调函数
    const handleCreated = (editor) => {
      console.log('created', editor);
      editorRef.value = editor; // 记录 editor 实例，重要！
    };
    const handleChange = (editor) => {
      console.log('change:', editor.getHtml());
      emit('update:modelValue', editor.getHtml());

    };
    const handleDestroyed = (editor) => {
      console.log('destroyed', editor);
    };
    const handleFocus = (editor) => {
      console.log('focus', editor);
      emit('focus', editor);
    };
    const handleBlur = (editor) => {
      console.log('blur', editor);
      emit('blur', editor);
    };
    const customAlert = (info, type) => {
      alert(`【自定义提示】${type} - ${info}`);
    };
    const customPaste = (editor, event, callback) => {
      // console.log('ClipboardEvent 粘贴事件对象', event);

      // 自定义插入内容
      // editor.insertText('xxx');

      // 返回值（注意，vue 事件的返回值，不能用 return）
      // callback(false); // 返回 false ，阻止默认粘贴行为
      callback(true); // 返回 true ，继续默认的粘贴行为
    };

    const insertText = () => {
      const editor = editorRef.value;
      if (editor == null) return;

      editor.insertText('hello world');
    };

    const printHtml = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      console.log(editor.getHtml());
    };

    const disable = () => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.disable();
    };   

    return {
      editorRef,
      mode: 'default',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
      handleDestroyed,
      handleFocus,
      handleBlur,
      customAlert,
      customPaste,
      insertText,
      printHtml,   
      loading   
      // disable
    };
  }
});
</script>
<style lang="scss">
  .w-e-full-screen-container{
    z-index: 9999;
  }
  .w-e-text-container {
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #ddd;
      background-color: #fff;
    }
    tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #ddd !important;
    }
    tr:first-child > th {
      border-top: 0;
    }
    tr:first-child {
      &:hover {
        background-color: #fff;
      }
    }
    tbody > tr:hover {
      background-color: #eef3fb;
    }
    th {
      padding: 8px !important;
      line-height: 1.42857143 !important;
      border: 1px solid #ddd;
      background: #f5f2f0 !important;
    }
    td {
      border: 1px solid #ddd;
      padding: 8px !important;
      line-height: 1.42857143 !important;
    }
  }
</style>
