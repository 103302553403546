import type { App } from 'vue';

/**
 * 自定义指令文本复制
 * @method v-copy v-copy.icon v-copy.dbclick
 * @param {OBject} app  - vue页面根元素实例化的vue对象
 * @example
 * <div v-copy> 单击复制 </div>
 * <div v-copy.dblclick> 双击复制 </div>
 * <div v-copy.icon> icon复制 </div>
 */
import { ElMessage } from 'element-plus';

export default (app:App) => {
  app.directive('copy', (el, binding) => {
    // 双击触发复制
    if (binding.modifiers.dblclick) {
      el.addEventListener('dblclick', () => handleClick(el.innerText));
      el.style.cursor = 'copy';
    } else if (binding.modifiers.icon) { // 点击icon触发复制
      if (el.hasIcon) return;
      const iconElement = document.createElement('i');
      iconElement.setAttribute('class', 'el-icon-copy-document');
      iconElement.setAttribute('style', 'margin-left:5px');
      el.appendChild(iconElement);
      el.hasIcon = true;
      iconElement.addEventListener('click', () => handleClick(el.innerText));
      iconElement.style.cursor = 'pointer';
    } else { // 单击触发复制
      el.addEventListener('click', () => handleClick(el.innerText));
      el.style.cursor = 'copy';
    }
  });
};

const handleClick = (text:string) => {
  // 创建元素
  if (!document.getElementById('copyTarget')) {
    const copyTarget = document.createElement('input');
    copyTarget.setAttribute('style', 'position:fixed;top:0;left:0;opacity:0;z-index:-1000;');
    copyTarget.setAttribute('id', 'copyTarget');
    document.body.appendChild(copyTarget);
  }

  // 复制内容
  const input = <HTMLInputElement>document.getElementById('copyTarget');
  input.value = text;
  input.select();
  document.execCommand('copy');
  // alert('复制成功')
  ElMessage.success('复制成功');
};
