import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { setupStore } from './store';
import { store } from '@/stores';
import useI18n from './plugins/i18n';

import useElement from './plugins/element-plus';
import useVxeTable from './plugins/vxe-table';
import useComponents from './components/index';
// import useIframeListener from './plugins/iframe-listener';
import directives from './directive';
import 'virtual:svg-icons-register';
import '@gj/gplus-ui/lib/style.css';
import './assets/styles/base.scss';
import { microPlugin } from '@/plugins/micro';
import { getQueryVariable, isMicroApp } from '@/utils';
import { setXToen } from '@/utils/auth';
import {
  setEnvParams,
  sentryRelease,
  useVirtualEnv
} from '@gj/env-cli/virtual-env';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import {
  MainToMicroEventType,
  MicroAppType,
  useMicroAppNotice,
  useMicroAppProvide,
  useMicroBuriedPoint
} from '@gj/micro-frontend';

import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

useMicroAppProvide();
microPlugin();

async function appInit () {
  const { registerMicroApp, sendRouteTable, registerOneListener } =
    useMicroAppNotice();
  registerMicroApp(MicroAppType.UAC);
  sendRouteTable(router.options.routes);
  useMicroBuriedPoint();

  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true);
    }, 1000);
    registerOneListener(MainToMicroEventType.ROUTE_TABLE_LOADED, () => {
      clearTimeout(timer);
      resolve(true);
    });
  });
}

appInit().then(() => {
  const app = createApp(App);
  useElement(app);
  useComponents(app);
  // useIframeListener(app, router);
  useVxeTable(app);
  setupStore(app);
  app.use(directives);
  app.use(router);
  app.use(store);
  app.use(mavonEditor);
  useI18n(app, router);
  app.mount('#app');

  setEnvParams();

  const token = getQueryVariable('token', location.search);
  const origin = getQueryVariable('origin', location.search);

  if (token) {
    setXToen(token);
  }

  if (origin) {
    const { env } = useVirtualEnv<any>();
    const a = document.createElement('a');

    a.href = origin;

    env && (env.headers['g-origin'] = origin);

    const reg =
      /(?:(?:1[0-9][0-9]\.)|(?:2[0-4][0-9]\.)|(?:25[0-5]\.)|(?:[1-9][0-9]\.)|(?:[0-9]\.)){3}(?:(?:1[0-9][0-9])|(?:2[0-4][0-9])|(?:25[0-5])|(?:[1-9][0-9])|(?:[0-9]))/;
    const isIP4 = reg.test(a.host);

    if (isIP4 && env) {
      // 直接访问后端即可
      env.gateway.host = origin;
      env.headers['g-origin'] = null;
    }

    env.integrated = true;
  }

  if (import.meta.env.MODE !== 'development' && isMicroApp()) {
    const {
      env: { sentry }
    } = useVirtualEnv();
    const { env } = sentry || {};
    const release = sentryRelease();
    release &&
      Sentry.init({
        app,
        dsn: 'https://707d4044a3e740cb81de50f7dff07053@sentry.app.gerpgo.com/7',
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            // tracingOrigins: ["localhost", "my-site-url.com", /^\//],
          })
        ],
        tracesSampleRate: 1.0,
        release: release,
        environment: env
      });
  }
});

// VueBuriedPointInit();
// microPlugin();
