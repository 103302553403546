
import { defineStore } from 'pinia';
import { TabItem, RouterInfo } from '@/types/tabs';
const initTabs = [
  { name: 'dashboard', label: 'dashboard', fullPath: '/dashboard', fixed: false, close: false },
  { name: 'workbench', label: 'workbench', fullPath: '/workbench', fixed: false, close: false }
];
interface MultiTabsState {
  tabList: TabItem[]
}

/** 获取已经固定的 */
export const getCacheTabs = ():TabItem[] => localStorage.cacheTabs ? JSON.parse(localStorage.cacheTabs) : [];

export const useMultiTabsStore = defineStore({
  id: 'multi-tabs', // tabs 数据
  state: ():MultiTabsState => ({
    tabList: []
  }),
  getters: {
    getTabList ():TabItem[] {
      return this.tabList;
    }
  },
  actions: {
    setTabList (val:RouterInfo) {
      const element = this.tabList.find(v => v.name === val.name);
      const item = {
        name: val.name as string,
        path: val.path,
        label: val.meta.label,
        fullPath: val.fullPath || val.path,
        fixed: val.meta.fixed || false,
        close: val.meta.close || true
      };
      if (!element) {
        this.tabList.push(item);
      } else {
        if (val.meta?.dynamic) {
          this.tabList.splice(this.tabList.findIndex(v => v.name === val.name), 1, item);
        }
      }
    },
    initState () {
      this.tabList = [...initTabs, ...getCacheTabs()];
    },
    removeTab (index:number) {
      this.tabList.splice(index, 1);
    },
    changeFixed (index:number, fixed:boolean) {
      this.tabList[index].fixed = fixed;
    },
    handleCommand (element:TabItem) {
      this.tabList = [...initTabs, element];
    }
  }
});
