<template>
  <div class="gj-upload-excel">
    <div class="gj-upload-input-box">
      <input
        ref="uploadInputRef"
        class="gj-upload-input"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        type="file"
        @change="fileChange" />
      <div class="gj-upload-btn-group">
        <gj-button
          type="primary"
          :loading="isLoading"
          @click="onClickTrigger">
          {{ btnText }}
        </gj-button>
        <gj-button
          v-if="showTemplate"
          type="minor"
          @click.stop="download(`${api.baseURL}${downloadUrl}`)">
          下载模版
        </gj-button>
      </div>
    </div>
    <ul class="gj-upload-files">
      <li
        v-for="(file,index) in files"
        :key="file.id || index"
        class="gi-upload-files-item">
        <div class="gj-upload-info-item">
          <div class="gj-upload-info-item-left">
            <i class="el-icon-document"></i>
            <span class="gj-upload-text">{{ file?.fileInfo?.name }}</span>
          </div>
          <div class="gj-upload-info-item-right">
            <span v-if="isLoading && isProgress" class="gj-upload-info-rate">{{ file.rate }}%</span>
            <span v-if="file.uploadStatus === 'success'" class="gj-upload-success">
              <i class="el-icon-circle-check"></i>上传成功
            </span>
            <span v-if="file.uploadStatus === 'fail'" class="gj-upload-fail">
              <i class="el-icon-warning-outline"></i>上传失败
            </span>
            <span class="gj-upload-delete" @click="deleteFile(index)">
              <i class="el-icon-delete"></i>
            </span>
            <span>
              <gj-button
                v-if="file.uploadFailLogName"
                type="text"
                size="mini"
                @click="downloadFileById(file.uploadFailLogName)">
                导出错误日志
              </gj-button>
            </span>
          </div>
        </div>
        <!-- <div v-if="file.importStatus && !file.importStatus.allSuccess" class="gj-upload-import">
          <span>已导入成功{{ file.importStatus.successCount }}条记录，失败{{ file.importStatus.errorCount }}条</span>
          <div class="gj-upload-import-right">
            <i class="el-icon-download"></i>
            <span @click="downloadFileById(file.importStatus.fileName)">下载导入失败项</span>
          </div>
        </div> -->
        <el-progress
          v-if="isLoading && isProgress"
          :percentage="file.rate"
          :stroke-width="4"
          :show-text="false">
        </el-progress>
      </li>
    </ul>
    <ul class="gj-upload-prompt">
      <li v-for="(prompt,index) in promptList" :key="index">
        <span></span>{{ prompt }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { reactive, ref, toRefs, defineComponent } from 'vue';
import axios from 'axios';
import api, { postformProgress } from '@/api/http';
import { download } from '@/utils/index';
import { BaseService } from '@/api/base';
import { FileItem } from '@/model/base';
import useGlobal from '@/common/useGlobal';

export default defineComponent({
  name: 'GjUploadExcel',
  props: {
    /** 导入的按钮 文本自定义 */
    btnText: {
      type: String,
      default: '导入数据'
    },
    showTemplate: {
      type: Boolean,
      default: true,
      required: false
    },
    downloadUrl: {
      type: String,
      required: false
    },
    downloadFailUrl: {
      type: String
    },
    isProgress: {
      type: Boolean,
      default: false
    },
    /** 上传附加的参数 模块标识和模块id */
    params: {
      type: Object,
      default: () => { }
    },
    /** 上传地址 */
    action: {
      type: String
    },
    /** 提示文本列表 */
    promptList: {
      type: Array
    }
    /**
     * 导入数据
     * 初始值 格式为{status:'0',id:null,data:null}
     * 导入成功或者部分成功 格式为{status:'1',id:fileId,data:res.data}
     * 导入失败 格式为{status:'2',id:fileId,data:res.data}
     */
    // importData: {
    //   type: Object
    // }
  },
  emits: ['uploadSuccess'],
  setup (props, { emit }) {
    // 隐藏input标签 做事件传递
    const uploadInputRef = ref(null);
    const onClickTrigger = () => uploadInputRef.value.click();
    // 文件的维护
    const state = reactive<{files:FileItem[], isLoading:boolean}>({
      files: [],
      isLoading: false
    });
    const source = ref(null);
    const fileChange = (e:Event) => {
      const target = e.target as HTMLInputElement;
      const rawFiles = target.files[0];
      if (!rawFiles) return;
      state.files.push({
        uploadStatus: 'uploading',
        fileInfo: rawFiles,
        uploadFailLogName: ''
      });
      // console.log(state.files);
      const index = state.files.length;
      // 传参
      const formData = new FormData();
      formData.append('file', rawFiles);
      const params = props.params || {};
      Object.keys(params).forEach(v => {
        formData.append(v, params[v]);
      });
      state.isLoading = true;
      source.value = axios.CancelToken.source();
      postformProgress(props.action ? props.action : '/file/attachment/upload', formData, e => {
        state.files[index - 1].rate = (e.loaded / e.total * 100 | 0);
        // console.log(state.files);
      }, source.value.token).then(res => {
        // debugger
        if (res.data.code === 0) {
          state.files[index - 1].id = res.data.id;
          state.files[index - 1].uploadStatus = 'success';
          state.files[index - 1].importStatus = res.data.data;
          // console.log(state.files);
          // 通过emit把事件传给父亲组件  获取上传文件导入数据的具体信息
          emit('uploadSuccess', res);
        } else {
          state.files[index - 1].uploadStatus = 'fail';
          if (res.data.msg) {
            $gjMessage.warning(res.data.msg);
          }
        }
        if (res.data.code !== 0 && res.data.data) {
          state.files[index - 1].uploadFailLogName = res.data.data;
        }

        // TODO: 后台导入数据接口统一 就可以通过promise 链式处理
      }).catch(err => {
        // console.log(err);
        state.files[index - 1].uploadStatus = 'fail';
      }).finally(() => {
        state.isLoading = false;
        source.value = null;
      });
    };

    // 监听来着父组件的导入数据情况
    // watch(props.importData, (newVal) => {
    //   const { status, id, data } = newVal;
    //   if (isMeaningful(id)) {
    //     state.isLoading = false;
    //     const index = state.files.findIndex(v => v.id === id);
    //     state.files[index].uploadStatus = status;
    //     // TODO:处理导入数据服务器响应后的展示
    //     console.log(data);
    //   }
    // });
    const { $gjMessage } = useGlobal();
    // 删除文件 并取消正在上传的事件
    const deleteFile = async (index:number) => {
      // 删除文件服务器中的文件
      const file = state.files[index];
      if (file.id) {
        try {
          await BaseService.removeAttachmentById(file.id);
        } catch (err) {
          $gjMessage.warning('文件删除失败');
        }
      }
      state.files.splice(index, 1);
      state.isLoading = false;
      if (source.value) {
        source.value.cancel('cancel upload');
      }
    };
    const downloadFileById = (fileName:string) => {
      BaseService.downloadResource('/manager/system/orgManage/download?fileName=' + fileName, {});
      // BaseService.download(props.downloadFailUrl, { fileName: fileName });
    };

    return {
      uploadInputRef,
      onClickTrigger,
      fileChange,
      ...toRefs(state),
      api,
      deleteFile,
      downloadFileById,
      download
    };
  }
});

</script>
<style lang="scss" >
.gj-upload-excel {
  .gj-upload-input-box {
    .gj-upload-input {
      display: none;
    }
  }

  .gj-upload-files {
    margin-top: 20px;
    .gi-upload-files-item {
      margin-bottom: 8px;
      .gj-upload-info-item {
        align-items: center;
        color: var(--color-grey-8);
        display: flex;
        font-size: 12px;
        height: 24px;
        justify-content: space-between;

        .gj-upload-info-item-left {
          .el-icon-document {
            font-size: 15px;
            margin: 0 8px 0 8px;
          }

          .gj-upload-text {
            max-width: calc(100% - 200px);
          }
        }

        .gj-upload-info-item-right {
          .gj-upload-info-rate {
            color: var(--color-blue-6);
          }

          .gj-upload-success {
            color: var(--color-green-4);

            > i {
              font-size: 14px;
              margin-right: 8px;
            }
          }

          .gj-upload-fail {
            color: var(--color-red-4);

            > i {
              font-size: 14px;
              margin-right: 8px;
            }
          }

          .gj-upload-delete {
            display: none;
            margin-right: 12px;
            cursor: pointer;
          }
          .gj-upload-delete:hover{
            color: var(--color-red-4);
          }
        }
      }

      .gj-upload-info-item:hover {
        background-color: #EEF3FB;

        .gj-upload-info-item-right {
          .gj-upload-info-rate {
            display: none;
          }

          .gj-upload-success {
            display: none;
          }

          .gj-upload-fail {
            display: none;
          }

          .gj-upload-delete {
            display: inline-block;
          }
        }
      }

      .gj-upload-import {
        align-items: center;
        background-color: #FFF1F0;
        display: flex;
        font-size: 12px;
        height: 26px;
        justify-content: space-between;
        padding: 0 12px;

        >span {
          color: var(--color-red-4);
        }

        .gj-upload-import-right {
          color: var(--color-grey-8);
          cursor: pointer;
          >i {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .gj-upload-prompt {
    background-color: var(--color-bg-1);
    padding: 8px;
    margin-top: 16px;

    li {
      color: var(--color-grey-7);
      font-size: 12px;
      line-height: 18px;
      list-style: none;
      padding: 4px;

      >span {
        background-color: rgba($color: #666666, $alpha: 0.4);
        border-radius: 50%;
        display: inline-block;
        height: 8px;
        margin-right: 8px;
        width: 8px;
      }
    }
  }
}
</style>
