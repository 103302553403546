import { GetterTree } from 'vuex';
import { RootState } from '@/stores';
import { UserDocuments } from '@/types/documents';
import { State } from './state';
export type Getters = {
  getDocuments(state: State): UserDocuments | null;
}
export const getters: GetterTree<State, RootState> & Getters = {
  getDocuments: (state) => state.data
};
