export default [
  {
    path: '/tenant/dashboard',
    name: 'tenantDashboard',
    component: () => import('@/views/modules/tenant/dashboard.vue'),
    meta: { label: '首页', locale: 'dashboard', keepAlive: true }
  },
  {
    path: '/tenant/organization',
    name: 'tenantOrganization',
    component: () => import('@/views/modules/tenant/organization.vue'),
    meta: { label: '用户管理', keepAlive: true }
  },
  {
    path: '/tenant/department',
    name: 'tenantDepartment',
    component: () => import('@/views/modules/tenant/department.vue'),
    meta: { label: '部门管理', keepAlive: true }
  },
  {
    path: '/tenant/station',
    name: 'tenantStation',
    component: () => import('@/views/modules/tenant/station.vue'),
    meta: { label: '岗位管理', keepAlive: true }
  },
  {
    path: '/tenant/role',
    name: 'tenantRole',
    component: () => import('@/views/modules/tenant/role.vue'),
    meta: { label: '菜单权限', keepAlive: true, guard: true }
  },
  {
    path: '/tenant/authority',
    name: 'tenantAuthority',
    component: () => import('@/views/modules/tenant/authority.vue'),
    meta: { label: '数据权限', keepAlive: true }
  },
  {
    path: '/tenant/resource',
    name: 'resource',
    component: () => import('@/views/modules/tenant/resource.vue'),
    meta: { label: '菜单资源管理', keepAlive: true }
  },
  {
    path: '/tenant/package',
    name: 'Package',
    component: () => import('@/views/modules/tenant/package.vue'),
    meta: { label: '套餐管理', keepAlive: true }
  },
  {
    path: '/tenant/ability',
    name: 'Ability',
    component: () => import('@/views/modules/tenant/ability.vue'),
    meta: { label: '租户能力管理', keepAlive: true }
  },
  {
    path: '/tenant/bannerNotice',
    name: 'bannerNotice',
    component: () => import('@/views/modules/tenant/banner-notice.vue'),
    meta: { label: '租户通知', keepAlive: true }
  }

];
