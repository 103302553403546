<template>
  <div class="header-tab">
    <div class="header-tab-box" :class="[appLayout === 'horizontal' && 'is-horizontal']">
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleTabClick">
        <el-tab-pane
          v-for="(item,index) in tabList"
          :key="item.name"
          :name="item.name">
          <template #label>
            <div class="tabs-item">
              <span class="label-temp" @contextmenu.prevent="setRightMenu(item, $event)">{{ item.label }}</span>
              <el-icon v-if="item.fixed" @click.stop="changeTabFixed(index,false,item)">
                <ice-cream-square></ice-cream-square>
              </el-icon>
              <el-icon v-if="item.close && !item.fixed" @click.stop="handleRemoveTab(index,item.name)">
                <Close></Close>
              </el-icon>
            </div>
            <el-divider v-if=" activeName !==item.name" direction="vertical"></el-divider>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dropdown placement="bottom-end" @command="handleTabCommand">
      <div class="header-tab-right-icon">
        <i class="el-icon-caret-bottom"></i>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="close-other">
            关闭其他标签
          </el-dropdown-item>
          <el-dropdown-item command="close-all">
            关闭所有标签
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <navBarRight v-if="appLayout === 'horizontal'"></navBarRight>
  </div>
  <RightMenus
    v-model="showMenu"
    :menus="menus"
    :position="position">
  </RightMenus>
</template>

<script lang="ts">
import { ref, watch, defineComponent, toRefs, computed } from 'vue';
import { useCommonStore } from '@/store/modules/common';
import { useMultiTabsStore, getCacheTabs } from '@/store/modules/multilTaps';
import { TabItem } from '@/types/tabs';
import { useRoute, useRouter } from 'vue-router';
import navBarRight from './widget/nav-bar-right.vue';
import RightMenus from './widget/right-menus.vue';
import useRightMenu from './composables/useRightMenu';
import { Close, IceCreamSquare } from '@element-plus/icons';
import * as _ from 'lodash';

export default defineComponent({
  components: { navBarRight, RightMenus, Close, IceCreamSquare },
  setup () {
    // 全局通用数据中心
    const commonStore = useCommonStore();
    // 路由
    const router = useRouter();
    const route = useRoute();
    // 使用name做唯一标识
    const activeName = ref('');
    const multiTabsStore = useMultiTabsStore();
    const { currentRoute } = router;
    const listenersChangeRoute = () => {
      const name: string = currentRoute.value && !_.isEmpty(currentRoute.value.matched) && currentRoute.value.matched[0].components
        ? currentRoute.value.matched[0].components.default.name
        : '';
      if (_.isEmpty(name)) {
        return;
      }
      const path: string = currentRoute.value.path;
      // 设置组件keepalive
      const keepAliveViews = commonStore.keepAliveViews;
      if (!keepAliveViews.map(view => view.name).includes(name)) {
        keepAliveViews.push({
          name,
          path
        });
        commonStore.setKeepAliveViews(keepAliveViews);
      }
    };
    watch(() => route.path, () => {
      listenersChangeRoute();
    });
    // TODO:本地热刷后 activeName 值回归初始值了
    watch(() => currentRoute.value, (val) => {
      console.log(val);
    });
    /** 点击切换tab */
    const handleTabClick = (tab:any) => {
      const activeTab = multiTabsStore.tabList.find(v => v.name === tab.props.name);
      router.push(activeTab.fullPath);
    };
    /** 删除tab */
    const handleRemoveTab = (index:number, name:string) => {
      const prevTab = name === activeName.value ? multiTabsStore.tabList[index - 1] : null;
      // 清除固定栏缓存
      const cacheTabs = getCacheTabs();
      const cacheIndex = cacheTabs.findIndex(v => v.name === name);
      if (cacheIndex >= 0) {
        cacheTabs.splice(cacheIndex, 1);
        localStorage.setItem('cacheTabs', JSON.stringify(cacheTabs));
      }
      // tabList和路由跳转
      if (prevTab) {
        router.push(prevTab.fullPath).then(() => {
          multiTabsStore.removeTab(index);
        });
      } else {
        multiTabsStore.removeTab(index);
      }
      // keepAlive
      const keepAliveViews = commonStore.keepAliveViews;
      const viewIndex = keepAliveViews.findIndex(v => v === name);
      if (viewIndex >= 0) {
        keepAliveViews.splice(viewIndex, 1);
        commonStore.setKeepAliveViews(keepAliveViews);
      }
    };
    /** 切换是否固定到标签栏 */
    const changeTabFixed = (index:number, fixed:boolean, item:TabItem) => {
      const cacheTabs = getCacheTabs();
      if (fixed) {
        cacheTabs.push(item);
      } else {
        const cacheIndex = cacheTabs.findIndex(v => v.name === item.name);
        cacheTabs.splice(cacheIndex, 1);
      }
      multiTabsStore.changeFixed(index, fixed);
      localStorage.setItem('cacheTabs', JSON.stringify(cacheTabs));
    };
    const initAllState = () => {
      commonStore.setKeepAliveViews([]);
      localStorage.removeItem('cacheTabs');
      multiTabsStore.initState();
    };
    const handleTabCommand = (command:string) => {
      if (!['dashboard', 'workbench'].includes(activeName.value)) {
        if (command === 'close-other') {
          const element = multiTabsStore.tabList.find(v => v.name === activeName.value);
          if (element.fixed) {
            localStorage.setItem('cacheTabs', JSON.stringify([element]));
          }
          commonStore.setKeepAliveViews([activeName.value]);
          multiTabsStore.handleCommand(element);
        } else {
          router.push('/dashboard').then(() => {
            initAllState();
          });
        }
      } else {
        initAllState();
      }
    };
    const menus = ref([]);
    const { state: rightMenuState, setMenuState } = useRightMenu();
    const setRightMenu = (item:TabItem, event:MouseEvent) => {
      const newMenus = [
        {
          label: '刷新当前页面',
          click: () => {
            router.push({
              path: '/redirect',
              query: {
                url: item.fullPath
              }
            });
          }
        }
      ];
      const isDefault = ['dashboard', 'workbench'].includes(item.name);
      if (!isDefault) {
        newMenus.push({
          label: item.fixed ? '从标签栏取消固定' : '固定到标签栏',
          click: () => changeTabFixed(multiTabsStore.tabList.findIndex(v => v.name === item.name), !item.fixed, item)
        });
        newMenus.push({
          label: '关闭该标签页面',
          click: () => handleRemoveTab(multiTabsStore.tabList.findIndex(v => v.name === item.name), item.name)
        });
      }
      menus.value = newMenus;
      setMenuState(event);
    };
    /** 通过api删除的tab页签 */
    const closeTab = (name:string) => {
      multiTabsStore.removeTab(multiTabsStore.tabList.findIndex(v => v.name === name));
    };
    return {
      tabList: computed(() => multiTabsStore.tabList),
      appLayout: commonStore.appLayout,
      ...toRefs(rightMenuState),
      menus,
      activeName,
      changeTabFixed,
      handleRemoveTab,
      handleTabClick,
      handleTabCommand,
      setRightMenu,
      closeTab
    };
  }
});
</script>

<style lang="scss" >
.header-tab {
  display: none;
  background-color: var(--color-white);
  box-sizing: border-box;
  height: 36px;
  padding: 0 24px;

  .header-tab-box {
    margin-top: 4px;
    width: calc(100% - 35px);

    .el-tabs {
      .el-tabs__header {
        border: unset;
        .el-tabs__nav-next:hover, .el-tabs__nav-prev:hover{
          color: var(--color-blue-6);
        }
        .el-tabs__nav {
          border: unset;
          display: flex;
        }

        .el-tabs__item {
          align-items: center;
          border: unset;
          border-top-left-radius: var(--border-radius-2);
          border-top-right-radius: var(--border-radius-2);
          display: flex;
          height: 32px;
          line-height: 32px;
          padding: 0 !important;

          .tabs-item {
            align-items: center;
            display: flex;
            font-size: 13px;
            justify-content: space-between;
            min-width: 96px;
            padding: 0 8px;

            .label-temp {
              flex: auto;
            }

            .el-icon-close {
              background-color: unset;
              float: right;
              width: 14px;
            }
          }

          .el-divider--vertical {
            margin: 0;
          }

          .el-divider {
            background-color: var(--color-grey-4);
          }
        }

        .el-tabs__item.is-active {
          background-color: var(--color-grey-2);
          color: var(--color-blue-6);
        }
      }

      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        line-height: 32px;
      }
    }
  }

  .header-tab-box.is-horizontal {
    width: calc(100% - 240px);
  }

  .header-tab-right-icon {
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 35px;
    color:#cdcdcd;
    font-size: 18px;
    &:hover{
      color:var(--color-blue-6);
    }
  }
}
</style>
