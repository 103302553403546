
import { defineStore } from 'pinia';
import {AliveComponentView} from "@/model/base";

interface CommonState {
  keepAliveViews: Array<AliveComponentView>,
  appLayout: string
}

export const useCommonStore = defineStore({
  id: 'app-common', // 全局通用数据
  state: ():CommonState => ({
    keepAliveViews: [],
    appLayout: 'vertical'
  }),
  getters: {
    getKeepAliveViews (): Array<AliveComponentView> {
      return this.keepAliveViews;
    },
    getAppLayout ():string {
      return this.appLayout;
    }
  },
  actions: {
    setKeepAliveViews (keepAliveViews:Array<AliveComponentView>) {
      this.keepAliveViews = keepAliveViews;
    },
    setAppLayout () {
      this.appLayout = localStorage.appLayout ? localStorage.appLayout : 'vertical';
    }
  }
});
