<template>
  <app-nav-left v-if="appLayout === 'horizontal'"></app-nav-left>
  <app-nav-top v-else></app-nav-top>
  <div
    id="app-layout"
    class="app-layout"
    :class="[appLayout==='horizontal' && 'is-horizontal']">
    <div v-if="useNavTab" style="display: none;">
      <app-nav-tab></app-nav-tab>
    </div>
    <div id="app-content" class="app-content">
      <router-view v-slot="{ Component }">
        <keep-alive :include="keepAliveViews">          
          <component :is="Component"></component>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, provide } from 'vue';
import appNavTop from './widget/app-nav-top.vue';
import appNavLeft from './widget/app-nav-left.vue';
import appNavTab from './widget/app-nav-tab.vue';
import { useCommonStore } from '@/store/modules/common';
import { useMultiTabsStore } from '@/store/modules/multilTaps';
import { closeTabKey } from '@/symbols';
import {isMicroApp} from "@/utils";
const commonStore = useCommonStore();
const multiTabsStore = useMultiTabsStore();

onMounted(() => {
  // 读取配置到全局
  commonStore.setAppLayout();
  multiTabsStore.initState();
});
const appNavTabRef = ref(null);
const closeTab = (name:string) => {
  appNavTabRef.value.closeTab(name);
};

provide(closeTabKey, closeTab);
const appLayout = computed(() => commonStore.appLayout);
const keepAliveViews = computed(() => commonStore.keepAliveViews.map(view => view.name));
const useNavTab = isMicroApp();
// console.log('--------');
// setInterval(() => {
//   console.log(keepAliveViews); // xy-02 架构代码有区别，缓存这块
// }, 10000);

</script>

<style lang="scss" scoped>
.app-layout {
  height: 100%;
  position: relative;
  width: 100%;

  .app-content {
    box-sizing: border-box;
    height: 100%;
    // padding: 10px 16px 16px;
    position: relative;
    width: 100%;
  }
}

.app-layout.is-horizontal {
  float: left;
  height: 100%;
  width: calc(100% - 60px);
}
</style>
