<template>
  <div class="search-bar">
    <div class="search-bar-left">
      <slot></slot>
    </div>
    <div class="search-bar-right">
      <gj-button-icon
        v-if="layout.includes('high')"
        class="gj-svg-button"
        @click="isMore = !isMore">
        <gj-svg-icon name="gaojishanxuan" :size="18"></gj-svg-icon>
      </gj-button-icon>
      <gj-button
        v-if="layout.includes('search')"
        type="primary"
        @click="handleSearch">
        搜索
      </gj-button>
      <gj-button v-if="layout.includes('reset')" @click="handleReset">
        重置
      </gj-button>
      <gj-button-icon v-if="layout.includes('plan')" icon="el-icon-view"></gj-button-icon>
    </div>
  </div>
  <div v-if="isMore" class="search-bar-hight">
    <slot name="high"></slot>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType } from 'vue';
export default defineComponent({
  name: 'SearchBar',
  props: {
    /** 显示项配置 有high search reset plan */
    layout: {
      type: Array as PropType<string[]>,
      default: ():string[] => []
    }
  },
  emits: ['click-search', 'click-reset'],
  setup (props, { emit }) {
    const handleSearch = () => emit('click-search');
    const handleReset = () => emit('click-reset');
    const isMore = ref(false);
    return { handleReset, handleSearch, isMore };
  }
});
</script>

<style lang="scss" >
.search-bar {
  display: flex;
  // margin-bottom: 12px;
  min-height: 32px;
  width: 100%;

  .search-bar-left {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 208px);

    > div {
      margin-bottom: 12px;
      margin-right: 8px;
    }
  }

  .search-bar-right {
    align-items: center;
    display: flex;
    height: 32px;
  }
}

.search-bar-hight {
  align-items: center;
  display: flex;
  height: 32px;
  margin-bottom: 12px;
  width: 100%;

  > div {
    margin-right: 8px;
  }
}
</style>
