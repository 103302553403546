import { App } from '@vue/runtime-core';
import { createI18n } from 'vue-i18n';
import { Router } from 'vue-router';
import { toRaw } from 'vue';
import { merge } from 'lodash';

export default (app: App, router: Router, supportLocales = ['zh', 'en']) => {
  const devMode = import.meta.env.MODE === 'development';
  // 加载默认语言文件
  const locales = devMode
    ? import.meta.globEager('../assets/locales/*.json')
    : import.meta.globEager('../assets/locales/**/*.json');
  const messages = loadLocalMessages(locales);
  const i18n = createI18n({
    locale: 'zh',
    messages
  });
  // 注册i18n
  app.use(i18n);
  /**
   * 路由变化时懒加载模块语言文件
   * 路由信息：meta: { locale: 'ad/sp' }
   * 将会加载
   * 1. ad/sp/en.json
   * 2. ad/sp/zh.json
   * 3. ad/en.json
   * 4. ad/zh.json
   */
  // const jsonPrefix = import.meta.env.MODE === 'development' ? '../../public/locales' : '../../x-vue/locales';
  router.beforeEach(async (to, from, next) => {
    if (to?.meta?.locale && devMode) {
      const mainModule = (to.meta.locale as string).split('/')[0];
      const paths = [
        // 加载根模块文件
        ...supportLocales.map(locale => `../assets/locales/${mainModule}/${locale}.json`),
        // 加载子模块文件
        ...supportLocales.map(locale => `../assets/locales/${to.meta.locale}/${locale}.json`)
      ];
      const moduleMessages$ = paths.map(path => {
        return import(path)
          .then(module => loadLocalMessages({ [path]: module }))
          .catch(() => {});
      });
      const moduleMessages = await Promise.all(moduleMessages$);
      const messages = merge({}, toRaw(i18n.global.messages), ...moduleMessages);
      // 语言补丁
      supportLocales.forEach(locale => {
        i18n.global.setLocaleMessage(locale, messages[locale]);
      });
    }
    next();
  });
};

function loadLocalMessages (locales: Record<string, any>) {
  return Object.keys(locales)
    .map(key => {
      const { 1: modules, 2: locale } = key.match(/.*locales\/?(.*)\/(.*).json/);
      return {
        key,
        paths: modules ? [locale, modules.toUpperCase()] : [locale]
      };
    })
    .reduce((messages, { key, paths }) => {
      // 遍历模块路径依次赋值
      let moduleMessage = paths
        .reduce((messages, path) => {
          messages[path] = messages[path] || {};
          return messages[path];
        }, messages as Record<string, any>);
        // eslint-disable-next-line no-unused-vars
      moduleMessage = Object.assign(moduleMessage, locales[key].default);
      return messages;
    }, {} as any);
}
