import { reactive } from 'vue';
export default function useRightMenu () {
  const state = reactive({
    showMenu: false,
    position: {
      top: 0,
      left: 0
    }
  });
  const setMenuState = (event:MouseEvent) => {
    state.position.top = event.y;
    state.position.left = event.x;
    state.showMenu = true;
  };
  return { state, setMenuState };
}
