<template>
  <div class="operate-bar">
    <div class="operate-bar-left">
      <slot></slot>
    </div>
    <div class="operate-bar-right">
      <gj-tooltip
        v-if="layout.includes('download')"
        placement="top"
        :loading="downloadLoading"
        content="导出">
        <gj-button-icon icon="el-icon-download" @click="download"></gj-button-icon>
      </gj-tooltip>
      <gj-tooltip
        v-if="layout.includes('column')"
        placement="top"
        content="设置自定义列">
        <gj-button-icon
          icon="el-icon-setting"
          @click="isShow = true">
        </gj-button-icon>
      </gj-tooltip>
      <gj-tooltip
        v-if="layout.includes('help')"
        placement="top"
        content="帮助文档">
        <gj-button-icon class="gj-svg-button" @click="handleHelp">
          <gj-svg-icon name="bangzhushouce" :size="15"></gj-svg-icon>
        </gj-button-icon>
      </gj-tooltip>
    </div>
  </div>
  <gj-pop-up
    v-model="isShow"
    :is-footer="false"
    :padding="10"
    title="设置显示字段">
    <setting-colomns
      :sort-online-columns="sortOnlineColumns"
      @cancel="isShow = false"
      @change-columns="handleChangeColumns">
    </setting-colomns>
  </gj-pop-up>
</template>

<script lang="ts">
import { onMounted, ref, defineComponent, PropType } from 'vue';
import _ from 'lodash';
import { useRoute } from 'vue-router';
import { BaseService } from '@/api/base';
import { getHelpUrl } from './composables/useHelpUrl';
import settingColomns from './widget/setting-columns.vue';
import { ColumnItem } from '@/types/table';
export default defineComponent({
  name: 'OperateBar',
  components: { settingColomns },
  props: {
    // 布局设置
    layout: {
      type: Array,
      default: ():string[] => []
    },
    /** 列原始配置数组 */
    columns: {
      type: Array as PropType<ColumnItem[]>,
      default: ():[] => []
    },
    /** 双向绑定的列数据 */
    tableColumn: {
      type: Array,
      default: ():[] => []
    },
    /** 该表格的服务器标志 */
    columnsMark: {
      type: String
    },
    /** 导出的地址 */
    downloadUrl: {
      type: String,
      default: ''
    },
    /** 导出携带的参数 */
    downloadParams: {
      type: Object,
      default: () => {}
    },
    helpName: {
      type: String,
      default: ''
    }
  },
  emits: ['update:tableColumn'],
  setup (props, { emit }) {
    const isShow = ref(false);
    const sortOnlineColumns = ref([]);
    // 初始化columns设置
    const initColumnsSetting = () => {
      BaseService.getColumnsConfig(props.columnsMark).then(res => {
        const { data } = res;
        const config = data ? data.split(',') : [];
        // 未在后端设置自定义列时
        if (config.length === 0) {
          const isDefault = props.columns.some(v => v.default);
          if (isDefault) {
            sortOnlineColumns.value = props.columns.map(v => {
              if (v.default) {
                v.checked = true;
              }
              return v;
            });
          } else {
            sortOnlineColumns.value = props.columns.map(v => {
              v.checked = true;
              return v;
            });
          }
        } else {
          sortOnlineColumns.value = getSortColumns(props.columns, config);
        }
        emit('update:tableColumn', sortOnlineColumns.value.filter(v => v.checked));
      });
    };
    // 根据后台配置排序
    const getSortColumns = (columns:ColumnItem[], config:string[]) => {
      // const arr = _.cloneDeep(columns);
      // config.forEach((v, i) => {
      //   const index = arr.findIndex((n:ColumnItem) => n.field === v);
      //   if (index !== -1) {
      //     const val = arr[index];
      //     val.checked = true;
      //     arr.splice(index, 1);
      //     arr.splice(i, 0, val);
      //   }
      // });
      // return arr;
      return _.cloneDeep(columns).map((v:ColumnItem) => {
        if (config.includes(v.field)) {
          v.checked = true;
        }
        return v;
      });
    };
    const handleChangeColumns = ({ type, config }:{type:string, config?:string[]}) => {
      // 采用columns 原始配置
      let newConfig:string[] = [];
      if (type === 'default') {
        newConfig = props.columns.filter(v => v.default).map(v => v.field);
      } else {
        newConfig = config;
      }
      BaseService.setColumnsConfig({ module: props.columnsMark, columns: newConfig.join(',') }).then(() => {
        sortOnlineColumns.value = getSortColumns(props.columns, newConfig);
        isShow.value = false;
        emit('update:tableColumn', sortOnlineColumns.value.filter(v => v.checked));
      });
    };
    const route = useRoute();
    onMounted(() => {
      if (props.layout.includes('column') && props.columnsMark) {
        initColumnsSetting();
      }
    });
    const handleHelp = () => {
      const path = route.path.split('/');
      const name = props.helpName ? props.helpName : path[path.length - 1].replace(/-/g, '');
      window.open(getHelpUrl(name));
    };
    const downloadLoading = ref(false);
    const download = () => {
      // TODO:下载 通过prop的地址和参数
    };
    function menusClick (event:MouseEvent, item:any) {
      event.preventDefault();
      if (item && typeof item.click === 'function') {
        const val = item.click();
        if (val === false || val === null) {
          event.stopPropagation();
        }
      }
    }
    return {
      isShow,
      sortOnlineColumns,
      handleChangeColumns,
      menusClick,
      handleHelp,
      download,
      downloadLoading
    };
  }
});
</script>

<style lang="scss" scoped>
.operate-bar {
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin: 4px 0 12px;

  .operate-bar-left {
    align-items: center;
    display: flex;
  }

  .operate-bar-right {
    align-items: center;
    display: flex;
  }
}
</style>
