import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from "axios";
import { ElMessage } from "element-plus";
import qs from "qs";
// import { Base64 } from 'js-base64';
import { getTenantId, getXToen } from "@/utils/auth";
import router from "@/router";
import { isMicroApp } from "@/utils";
import { microAppNotice, MicroToMainEventType } from "@gj/micro-frontend";
import { useVirtualEnv } from "@gj/env-cli/virtual-env";
import { IntercepterFor302, IntercepterFor402 } from "./intercepter";

export function getApiAddr() {
  // 测试环境网关 http://gateway.apis.gerpgo.com/   生产环境网关 https://gateway.apist.gerpgo.com/
  // if (location.hostname.includes('trideer.') || location.hostname.includes('cloudgx.') || location.hostname.includes('.app.')) {
  //   return 'https://gateway.apist.gerpgo.com';
  // } else if (location.hostname.includes('.web.')) {
  //   return 'http://gateway.apis.gerpgo.com';
  // } else {
  //   return 'http://gateway.apis.gerpgo.com';
  // }
  //  env: {
  //       "gateway": {
  //         "host": "https://192.168.0.1"
  //       },
  //       "headers": {
  //         "g-route": "https://192.168.0.1",
  //         "g-shadow": "https://192.168.0.1"
  //       }
  //     },
  const { env } = useVirtualEnv();
  return env.gateway.host;
}

const message = (str: string) =>
  ElMessage({
    message: str,
    type: "error",
    duration: 3 * 1000,
  });
export let baseURL: string;
if (import.meta.env.MODE === "development") {
  // 请修改 .env 文件的 VITE_APP_BASE_URL 变量
  // baseURL = `${import.meta.env.VITE_APP_BASE_URL}`;
  baseURL = getApiAddr() + "/api";
} else {
  baseURL = getApiAddr() + "/api";
}

export const service = axios.create({
  baseURL: baseURL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 5, // request timeout
});
// const whiteUrls:string[] = [
//   '/oauth/anno/token',
//   '/oauth/anno/innerToken',
//   '/oauth/anno/registerCaptcha',
//   '/oauth/anno/register',
//   '/oauth/anno/getAuthInfo',
//   '/oauth/anno/forgetPwdConfirm'
// ];
// 请求拦截器
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // config.headers.Authorization = getToken();
    const {
      env: { headers },
    } = useVirtualEnv();
    if (headers) {
      // eslint-disable-next-line no-return-assign
      Object.entries(headers).forEach(
        ([key, value]) => (config.headers[key] = value)
      );
    }

    config.headers["g-tenant-id"] = getTenantId();
    // if (!whiteUrls.some(url => config.url.includes(url))) {
    //   config.headers.tenant = Base64.encode(getTenant());
    //   config.headers.token = `Bearer ${getToken()}`;
    //   config.headers.userId = getUserId();
    // }

    config.headers["x-auth-token"] = getXToen();
    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }
    return config;
  },
  (error: AxiosError) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response: AxiosResponse) => {
    // 状态码为200的响应数据
    if (response.status === 200) {
      await IntercepterFor302(response);
      await IntercepterFor402(response);
      const data = response.data;
      // 登录过期
      if ([401].includes(data.code)) {
        if (isMicroApp()) {
          microAppNotice.emit(MicroToMainEventType.UNAUTHORIZED, {});
          // window.parent.postMessage({ type: 'auth401' }, '*');
        } else {
          if (!window.location.pathname.includes("/login")) {
            ElMessage.closeAll();
            message("登录过期，请重新登录");
            if (!window.location.pathname.includes("/login")) {
              ElMessage.closeAll();
              router.push("/uac-app/login");
            }
          }
          // await store.dispatch(USER_RESET_USER);
        }

        return Promise.reject(response);
      }
      // 系统超时
      if (data.code === -2) {
        message(data.msg);
        return Promise.reject(response);
      }
      if (data.code < 0 && !data.data) {
        message(data.msg);
        return Promise.reject(response);
      }
      if (data.code === 401) {
        message(data.msg);
        return Promise.reject(response);
      }
      if (data instanceof Blob) {
        return response;
      }
      return Promise.resolve(response);
    } else {
      let errMsg = "网络错误，请重试";
      if (response.data && response.data.msg) {
        errMsg = response.data.msg;
      } else if (response.data && response.data.messages && response.data.messages.length > 0) {
        errMsg = response.data.messages[0];
      }
      message(errMsg);
      return Promise.reject(response);
    }
  },
  (error: AxiosError) => {
    // debugger;
    const response = error.response;
    ElMessage.closeAll();
    const code = response?.status;
    console.log("code:" + code);
    // debugger;
    switch (code) {
      case 401:
        // 凭证已过期，请重新登录
        // window.location.href = '/uac/login';
        if (isMicroApp()) {
          microAppNotice.emit(MicroToMainEventType.UNAUTHORIZED, {});
          // window.parent.postMessage({ type: 'auth401' }, '*');
        } else {
          if (!window.location.pathname.includes("/login")) {
            ElMessage.closeAll();
            message("登录过期，请重新登录");
            window.location.href = "/uac-app/login";
          }
        }
        break;
      default:
        message(
          response?.data.msg || response?.data.message || response?.data?.messages[0] || "网络错误，请重试"
        );
    }
    return Promise.reject(error);
  }
);

/**
 * 封装post请求用于FormData请求 上传文件
 *
 * @param {string} url 路由地址
 * @param {Object} params 参数
 * @param {Object} headers 请求头
 * @returns {promise} 返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export const postform = (
  url: string,
  params: FormData,
  headers?: { [key: string]: any }
) => service({ url: url, method: "POST", data: params, headers: headers });
/**
 * 封装post请求用于FormData请求 上传文件 并显示进度条
 *
 * @param {string} url 路由地址
 * @param {Object} params 参数
 * @param {*} onProgress 进度回调回调函数
 * @param {Object} cancelToken 取消请求
 * @param {Object} headers 请求头
 * @returns {promise} 返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export const postformProgress = (
  url: string,
  params: FormData,
  onProgress: (e: ProgressEvent) => void,
  cancelToken?: CancelToken,
  headers?: { [key: string]: any }
) =>
  service({
    url: url,
    method: "POST",
    data: params,
    onUploadProgress: (e: ProgressEvent) => {
      onProgress(e);
    },
    cancelToken,
    headers: headers,
  });

/**
 * get 请求
 *
 * @param {string} url 路由地址
 * @param {AxiosRequestConfig} config axios配置
 //  */
export function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return service.get<T>(url, config).then((res) => Promise.resolve(res.data));
}

/**
 * post 请求
 *
 * @param {string} url 路由地址
 * @param {any} params 参数
 * @param {AxiosRequestConfig} config axios配置
 */
export function post<T>(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  return service
    .post<T>(url, params, config)
    .then((res) => Promise.resolve(res.data));
}

/**
 * put 请求
 *
 * @param {string} url 路由地址
 * @param {any} params 参数
 * @param {AxiosRequestConfig} config axios配置
 */
export function put<T>(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  return service
    .put<T>(url, params, config)
    .then((res) => Promise.resolve(res.data));
}

/**
 * delete 请求
 *
 * @param {string} url 路由地址
 * @param {AxiosRequestConfig} config axios配置
 */
export function del<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return service
    .delete<T>(url, config)
    .then((res) => Promise.resolve(res.data));
}

/**
 * patch 请求
 *
 * @param {string} url 路由地址
 * @param {any} params 请求参数
 * @param {AxiosRequestConfig} config axios配置
 */
export function patch<T>(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  return service
    .patch<T>(url, params, config)
    .then((res) => Promise.resolve(res.data));
}

export default {
  get,
  post,
  put,
  del,
  patch,
  postform,
  postformProgress,
  baseURL,
};
