import { AxiosResponse } from 'axios';
import { setToken } from '@/utils/auth';
import { microAppNotice, MicroToMainEventType } from '@gj/micro-frontend';

/** 302 处理，跳往维护页面
 * {
    "traceId": null,
    "code": 302,
    "msg": "系统正在升级,请稍后重试",
    "data": {"apiUrlX":"http://www.baidu.com"},
    "extra": null,
    "success": false
  }
 * @param {*} response
 * @returns
 */

export function IntercepterFor302 (response: AxiosResponse) {
  return new Promise((resolve, reject) => {
    if (response?.data?.code !== 302) return resolve(true);
    // 这是网关放的, 网关不是很好写data
    const msg = getMessageFromResponse(response);
    // 通知基座重定向到系统维护页面
    microAppNotice.emit(MicroToMainEventType.REDIRECT, { url: msg });
    reject('系统正在升级,请稍后重试');
  });
}

/**
   * 402 处理，要求用户刷新
   * {
      "traceId": null,
      "code": 402,
      "msg": "系统已经更新,请刷新后重试",
      "data": "new jwt token test",
      "extra": null,
      "success": false
    }
   * @param {*} response
   */
export function IntercepterFor402 (response: AxiosResponse) {
  return new Promise((resolve, reject) => {
    if (response?.data?.code !== 402) return resolve(true);
    const message = '系统已经更新,请刷新后重试';
    const msg = getMessageFromResponse(response);
    // 通知基座刷新
    microAppNotice.emit(MicroToMainEventType.REAUTHORIZED, { token: msg });
    setToken(msg);
    reject(message);
  });
}

function getMessageFromResponse (response: AxiosResponse) {
  const msg = response?.data?.msg || response?.data?.messages;
  return (msg instanceof Array ? msg[0] : msg) || '';
}
