<template>
  <div class="app-nav-left">
    <router-link to="/dashboard">
      <div class="gj-icon-logo"></div>
    </router-link>
    <ul class="app-nav-list">
      <li
        v-for="item in menus"
        :key="item.id"
        :class="[active === item.id && 'is-active']"
        @click="handleClick(item)">
        <i class="el-icon-s-data"></i>
        <p>{{ item.label }}</p>
      </li>
    </ul>
    <transition name="el-fade-in">
      <div
        v-if="isShow"
        class="app-modal-nav"
        @click="isShow = false">
        <div class="app-model-nav-column">
          <div class="app-modal-nav-box" @click.stop>
            <div
              v-for="item in activeMenus"
              :key="item.id"
              class="nav-pane-item">
              <p>{{ item.label }}</p>
              <router-link
                v-for="itemChild in item.children"
                :key="itemChild.id"
                :to="itemChild.path">
                {{ itemChild.label }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { menus, MenusItem } from './composables/useMenu';
const active = ref(null);
const activeMenus = computed(() => active.value ? menus[menus.findIndex(v => v.id === active.value)].children : []);

const isShow = ref(false);
watch(isShow, () => {
  if (!isShow.value) active.value = null;
});
const handleClick = (item:MenusItem) => {
  active.value = item.id;
  if (!isShow.value) isShow.value = true;
};
</script>

<style lang="scss" scoped>
.app-nav-left {
  background-color: var(--color-blue-11);
  box-sizing: border-box;
  float: left;
  height: 100%;
  padding: 20px 0;
  width: 60px;

  .gj-icon-logo {
    background-image: url('/svg/logo.svg');
    background-position: 12px 0;
    background-repeat: no-repeat;
    background-size: 36px;
    height: 32px;
    width: 56px;
  }

  .app-nav-list {
    li {
      align-items: center;
      color: var(--color-grey-5);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;
      margin: 10px 0;

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    li:hover {
      color: var(--color-white);
    }

    li.is-active {
      color: #9CCCFF;
    }
  }
  // 查询1366-768分辨率
  @media screen and (max-height: 901px) {
    .app-nav-list {
      li {
        i {
          display: none;
        }
      }
    }
  }

  .app-modal-nav {
    background-color: rgba($color:#333333, $alpha: 0.6);
    height: 100%;
    left: 60px;
    position: fixed;
    top: 0;
    width: calc(100% - 60px);
    z-index: 1000;

    .app-modal-nav-box {
      background: var(--color-grey-1);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      writing-mode: vertical-lr;

      .nav-pane-item {
        display: flex;
        flex-direction: column;
        margin-top: 28px;
        padding: 0 24px;
        writing-mode: horizontal-tb;

        p {
          color: var(--color-grey-6);
          font-size: 13px;
          line-height: 20px;
          margin: 4px 0;
        }

        a {
          color: var(--color-grey-8);
          font-size: 13px;
          line-height: 20px;
          margin: 4px 0;
          min-width: 82px;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
