import { MutationTree } from 'vuex';
import { CommonState } from './state';
import { CommonMutationTypes } from './mutation-types';

export type Mutations<S = CommonState> = {
  [CommonMutationTypes.SET_KEEP_ALIVE_VIEWS](state: S, payload: string[]): void,
  [CommonMutationTypes.SET_APP_LAYOUT](state:S, payload:string):void
}

export const mutations: MutationTree<CommonState> & Mutations = {
  [CommonMutationTypes.SET_KEEP_ALIVE_VIEWS] (state: CommonState, keepAliveViews: string[]) {
    state.keepAliveViews = keepAliveViews;
  },
  [CommonMutationTypes.SET_APP_LAYOUT] (state:CommonState, mode:string) {
    state.appLayout = mode;
  }
};
