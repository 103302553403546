export default [
  {
    path: '/open-platform/interfaces-tree',
    name: 'OpenPlatformInterfacesTree',
    component: () => import('@/views/modules/open-platform/interfaces/interfaces-tree.vue'),
    meta: { label: '前台接口视图', keepAlive: true }
  },
  {
    path: '/open-platform/interfaces-list',
    name: 'OpenPlatformInterfacesList',
    component: () => import('@/views/modules/open-platform/interfaces/interfaces-list.vue'),
    meta: { label: '后台接口管理', keepAlive: true }
  },
  {
    path: '/open-platform/interfaces-business',
    name: 'OpenPlatformInterfacesBusiness',
    component: () => import('@/views/modules/open-platform/interfaces/interfaces-business.vue'),
    meta: { label: '业务接口管理', keepAlive: true }
  },
  {
    path: '/open-platform/limit-rule-config-list/:id',
    name: 'LimitRuleConfigList',
    component: () => import('@/views/modules/open-platform/interfaces/limit-rule-config-list.vue'),
    meta: { label: '限流规则列表', keepAlive: true }
  },
  {
    path: '/open-platform/audit-list',
    name: 'OpenPlatformAuditList',
    component: () => import('@/views/modules/open-platform/audit/audit-list.vue'),
    meta: { label: '接口审核', keepAlive: true }
  },
  {
    path: '/open-platform/banner-list',
    name: 'OpenPlatformBannerList',
    component: () => import('@/views/modules/open-platform/banner/banner-list.vue'),
    meta: { label: 'banner管理', keepAlive: true }
  },
  {
    path: '/open-platform/package-config-list',
    name: 'OpenPlatformPackageConfigList',
    component: () => import('@/views/modules/open-platform/package/package-config-list.vue'),
    meta: { label: '收费标准配置', keepAlive: true }
  },
  {
    path: '/open-platform/customer-recharge-statistics',
    name: 'OpenPlatformCustomerRechargeStatistics',
    component: () => import('@/views/modules/open-platform/statistics/customer-recharge-statistics.vue'),
    meta: { label: '客户充值统计', keepAlive: true }
  },
  {
    path: '/open-platform/customer-deduction-list',
    name: 'OpenPlatformCustomerDeductionDetail',
    component: () => import('@/views/modules/open-platform/deduction/customer-deduction-list.vue'),
    meta: { label: '客户扣款明细', keepAlive: true }
  },
  {
    path: '/open-platform/deduction-detail-list/:id',
    name: 'OpenPlatformCustomerDeductionDetailList',
    component: () => import('@/views/modules/open-platform/deduction/deduction-detail-list.vue'),
    meta: { label: '客户扣款流水列表', keepAlive: false }
  },
  {
    path: '/open-platform/tenant-manage',
    name: 'OpenPlatformTenantManage',
    component: () => import('@/views/modules/open-platform/tenant/tenant-manage.vue'),
    meta: { label: '租户管理', keepAlive: true }
  },
  {
    path: '/open-platform/tenant-user-manage-detail',
    name: 'OpenPlatformTenantUserManageDetail',
    component: () => import('@/views/modules/open-platform/tenant/tenant-user-manage-detail.vue'),
    meta: { label: '用户管理', keepAlive: true }
  },
  {
    path: '/open-platform/sub-account-manage/:id',
    name: 'OpenPlatformSubAccountManage',
    component: () => import('@/views/modules/open-platform/tenant/sub-account-manage.vue'),
    meta: { label: '管理子账号', keepAlive: false }
  },
  {
    path: '/open-platform/work-order-list',
    name: 'OpenPlatformWordOrderList',
    component: () => import('@/views/modules/open-platform/service/work-order-list.vue'),
    meta: { label: '工单管理', keepAlive: true }
  },
  {
    path: '/open-platform/notice-list',
    name: 'OpenPlatformNoticeList',
    component: () => import('@/views/modules/open-platform/notice/notice-list.vue'),
    meta: { label: '公告管理', keepAlive: true }
  },
  {
    path: '/open-platform/qa-list',
    name: 'OpenPlatformQAList',
    component: () => import('@/views/modules/open-platform/qa/qa-list.vue'),
    meta: { label: 'Q&A文档管理', keepAlive: true }
  },
  {
    path: '/open-platform/qa-group-list',
    name: 'OpenPlatformQAGroupList',
    component: () => import('@/views/modules/open-platform/qa-group/qa-group-list.vue'),
    meta: { label: 'Q&A文档分组管理', keepAlive: true }
  },
  {
    path: '/open-platform/gateway-router-list',
    name: 'OpenPlatformGatewayRouterList',
    component: () => import('@/views/modules/open-platform/gateway-router/gateway-router-list.vue'),
    meta: { label: '路由管理', keepAlive: true }
  },
  {
    path: '/open-platform/return-batch-list',
    name: 'OpenPlatformReturnBatchList',
    component: () => import('@/views/modules/open-platform/return-batch/return-batch-list.vue'),
    meta: { label: '接口回归测试', keepAlive: true }
  },
  {
    path: '/open-platform/sampled-data-list',
    name: 'OpenPlatformSampledDataList',
    component: () => import('@/views/modules/open-platform/sampled-data/sampled-data-list.vue'),
    meta: { label: '采样数据管理', keepAlive: true }
  },
  {
    path: '/open-platform/auto-test-list',
    name: 'OpenPlatformAutoTestList',
    component: () => import('@/views/modules/open-platform/auto-test/auto-test-list.vue'),
    meta: { label: '自动化批次配置', keepAlive: true }
  }
];
