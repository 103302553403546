<template>
  <div v-if="$props?.type === 'DATA_AUTH'" style="margin-bottom: 16px">
    <gj-input
      v-model="search.permGroupName"
      suffix-icon="el-icon-search"
      style="width: 200px;margin-right: 12px;"
      maxlength="50"
      placeholder="请输入权限组">
    </gj-input>
    <gj-select
      v-model="search.permType"
      style="width: 200px;margin-right: 12px;"
      placeholder="请选择授权类型">
      <el-option
        v-for="item in typeOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"></el-option>
    </gj-select>
    <gj-input
      v-model="search.content"
      suffix-icon="el-icon-search"
      maxlength="50"
      style="width: 200px;margin-right: 12px;"
      placeholder="请输入操作内容">
    </gj-input>
  </div>
  <gj-vxe-table
    :list="tableData"
    :columns="proxyColumns"
    :loading="loading"
    :height="500"
    :pager-config="pagerConfig"
    @pagerChange="handlePaperChange">
    <template #userName="{row}">
      {{ row.userName || row.createUserName || '-' }}
    </template>
    <template #content="{row}">
      <el-tooltip
        class="box-item"
        effect="dark"
        :content="row.content"
        placement="top-start"
        popper-class="log-row-tooltip">
        <span class="row-content">{{ row.content || row.content || '-' }}</span>
      </el-tooltip>
    </template>
  </gj-vxe-table>
</template>
<script lang="ts">
import { ColumnItem } from '@/types/table';
import { defineComponent, PropType, ref, onMounted, toRefs, reactive, watch } from 'vue';
import { useQuery } from '@/common/useQuery';
import { baseURL, service } from '@/api/http';
import { Method } from 'axios';
export default defineComponent({
  name: 'GjLogTable',
  props: {
    url: {
      type: String,
      required: true
    },
    method: {
      type: String,
      default: 'post'
    },
    params: {
      type: Object,
      default: () => {}
    },
    model: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    columns: {
      type: Array as PropType<ColumnItem[]>,
      default: null
    },
    height: {
      type: Number,
      default: 400
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const search = reactive({
      permGroupName: '',
      permType: '',
      content: ''
    });

    const typeOptions = [
      { value: '所有权限', label: '所有权限' },
      { value: '商品权限', label: '商品权限' },
      { value: '供应商权限', label: '供应商权限' },
      { value: '产品权限', label: '产品权限' },
      { value: '仓库权限', label: '仓库权限' },
      { value: '站点权限', label: '站点权限' }
    ];

    const proxyColumns = ref<ColumnItem[]>([]);
    onMounted(() => {
      if (props.columns && props.columns.length > 0) {
        proxyColumns.value = props.columns;
      } else {
        proxyColumns.value = [
          { id: 'createDate', title: '操作时间', field: 'createTime', minWidth: '120px' },
          { id: 'opUser', title: '操作用户', field: 'userName', minWidth: '100px', slots: { default: 'userName' } },
          { id: 'op', title: '操作分类', field: 'op', minWidth: '120px', ...(props.options && { options: props.options }) },
          { id: 'content', title: '操作内容', field: 'content', minWidth: '300px' }
        ];
      }
    });
    const tableData = ref([]);
    const getData = () => {
      if (props.type === 'DATA_AUTH') {
        logState.loading = true;
        const params: any = {
          page: logState.pagerConfig.page,
          pagesize: logState.pagerConfig.pagesize,
          ...search
        };
        for (const key in params) {
          params[key] === '' && delete params[key];
        }
        service.request({
          url: props.url,
          method: props.method.toUpperCase() as Method,
          ...(props.method.toUpperCase() === 'GET' && { params: params }),
          ...(props.method.toUpperCase() === 'POST' && { data: params }),
          baseURL: baseURL.replace('/api', '')
        }).then(({ data }) => {
          if (data) {
            tableData.value = (data.data.rows || []);
            logState.pagerConfig = {
              total: Number(data.data.total),
              pagesize: data.data.pagesize,
              page: data.data.page
            };
          }
        }).finally(() => {
          logState.loading = false;
        });
      } else {
        logState.loading = true;
        const params = {
          ...props.params,
          pageNum: logState.pagerConfig.page,
          pageSize: logState.pagerConfig.pagesize,
          body: {
            model: props.model
          }
        };
        service({
          url: props.url,
          method: props.method.toUpperCase() as Method,
          ...(props.method.toUpperCase() === 'GET' && { params: params }),
          ...(props.method.toUpperCase() === 'POST' && { data: params })
        }).then(({ data }) => {
          if (data) {
          // debugger;
            tableData.value = (data.data.rows || []);
            logState.pagerConfig = {
              total: Number(data.data.total),
              pagesize: data.data.pageSize,
              page: data.data.pageNum
            };
          }
        }).finally(() => {
          logState.loading = false;
        });
      }
    };
    const { state: logState, handlePaperChange, debounceGetDate } = useQuery(getData);

    watch(search, (val) => {
      debounceGetDate();
    });

    return {
      proxyColumns,
      tableData,
      ...toRefs(logState),
      handlePaperChange,
      search,
      typeOptions
    };
  }
});
</script>
<style lang="scss" scoped>
  .row-content{
    display: inline-block;
    width: 100%;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<style>
 .log-row-tooltip{
    max-width: 800px;
    max-height: 300px;
    overflow-y: auto;
  }
</style>
