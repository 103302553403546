import { BaseService } from '@/api/base';
import { ElMessage } from 'element-plus';

const message = (str: string) => ElMessage({
  message: str,
  type: 'success',
  duration: 3 * 1000
});

/**
 * 判断对象是否有意义 map对象和数组 不能使用
 * @param {string} object
 * @example
 * let a = null
 * isMeaningful(a) = false
 */
export const isMeaningful = (object:string) => {
  return object !== null && object !== undefined && object !== '';
};
/**
 * 对象转字符串
 * @param { Object } params
 * @returns {string} 字符串
 * @example queryParamsMap({a:1,b:2}) => a=1&b=2
 */
export const queryParamsMap = (params:{[key:string]:string}) => {
  const arr = [];
  for (const i in params) {
    if (isMeaningful(params[i])) {
      arr.push(`${i}=${params[i]}`);
    }
  }
  return arr.join('&');
};
/**
 * 下载文件
 * @param { String } href - 下载的文件地址
 * @param { String } name - 文件的名称
 * @example
 *下载图片
 * download('https://lzw.me/images/gravatar.gif', 'lzwme-gravatar');
 *下载一个连接
 * download('https://lzw.me', 'lzwme-index.html');
 * href 为 data URIs 示例
 * download('data:,Hello%2C%20World!', 'data-uris.txt');
 * download('data:text/plain;base64,SGVsbG8sIFdvcmxkIQ%3D%3D', 'data-uris.txt');
 * canvas 下载示例
 * download(canvas.toDataURL(), 'download-image');
 * 二进制blob下载
 * const href = URL.createObjectURL(blob);
 * download(href, 'download-text.txt');
 * URL.revokeObjectURL(href);
*/
export const download = (href:string, name?:string) => {
  const a = document.createElement('a');
  a.setAttribute('href', href);
  if (name) {
    a.setAttribute('download', name);
  }
  a.click();
};

/**
* 检测图片是否存在
* @param  {String}  url
*/
export const imageIsExist = (url:string) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function () {
      if (img.complete === true) {
        resolve(true);
        img = null;
      }
    };
    img.onerror = function () {
      resolve(false);
      img = null;
    };
    img.src = url;
  });
};

/** 获取某个对象的类型（小写） */
export const getType = <T>(obj: T): string => {
  const obj_ = Object.prototype.toString.call(obj);
  return obj_.match(/\[object (.*?)\]/)[1].toLowerCase();
};

/**
 * 权限控制 用于不适用使用v-has指令场景
 * @param { string | string[] } path - 用于判断的资源路径，多个条件传数组
 */
export const accessControl = (path: string | string[]): boolean => {
  if (getType(path) === 'string') {
    return BaseService.userRoles.includes(path as string);
  }
  if (getType(path) === 'array') {
    return (path as string[]).map((item: string) => BaseService.userRoles.indexOf(item) > -1).some((item: boolean) => item);
  }
};

export function getQueryVariable (variable: string, search: string) {
  const query = (search || window.location.search).substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return undefined;
}

/** 复制指定内容 */
export const copy = (val:any) => {
  const input = document.createElement('input'); // 直接构建input
  input.value = val;
  document.body.appendChild(input); // 添加临时实例
  input.select(); // 选择实例内容
  document.execCommand('Copy'); // 执行复制
  document.body.removeChild(input); // 删除临时实例
  message('复制成功');
};

export * from './micro';

// 判断一个字符串是否为JSON字符串
export const isJSON = (str: string) => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      } else {
        return false;
      }

    } catch (e) {
      return false;
    }
  } else if (typeof str === 'object' && str) {
    return true;
  }
};
