import type { App } from 'vue';
import { BaseService } from '@/api/base';

export default (app:App) => {
  app.directive('has', {
    mounted (el, binding) {
      const has: string = binding.value;
      const hasArr: string[] = [];
      // debugger;
      BaseService.userRoles.forEach(element => {
        hasArr.push(element);
      });

      if (!hasArr.includes(has)) {
        el.parentNode.removeChild(el);
      }
    }
  });
};
