<template>
  <div class="nav-bar-right" :class="[appLayout==='horizontal' &&'is-horizontal']">
    <!-- <i class="el-icon-service"></i> -->
    <!-- <gj-badge
      :value="200"
      :max="99"
      type="danger"
      class="badge-bell">
      <i class="el-icon-bell"></i>
    </gj-badge> -->
    <gj-dropdown @command="handleCommand">
      <div class="nav-bar-userInfo">
        <el-avatar :size="28" :src="circleUrl"></el-avatar>
        <span>{{ userInfo.name }}</span>
      </div>
      <template #dropdown>
        <!-- <gj-dropdown-item command="user-center">
          个人中心
        </gj-dropdown-item> -->
        <!-- <gj-dropdown-item command="setting-layout">
          导航布局设置
        </gj-dropdown-item> -->
        <gj-dropdown-item command="quit-account">
          退出账号
        </gj-dropdown-item>
      </template>
    </gj-dropdown>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useCommonStore } from '@/store/modules/common';
import { removeTenantId, removeToken } from '@/utils/auth';
import AuthService from '@/api/auth';
const commonStore = useCommonStore();
const circleUrl = ref('https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png');
const router = useRouter();
const handleCommand = async (command:string) => {
  console.log(command);
  if (command === 'quit-account') {
    await AuthService.logout();
    removeToken();
    removeTenantId();
    router.replace('/login');
  }
};
const appLayout = computed(() => commonStore.appLayout);
const userInfo = { name: '' };
</script>

<style lang="scss" >
.nav-bar-right {
  align-items: center;
  display: flex;
  height: 40px;

  .badge-bell {
    margin: 0 8px;

    .el-icon-bell {
      color: var(--color-white);
      font-size: 18px;
    }
  }

  .el-icon-service {
    color: var(--color-white);
    font-size: 18px;
    margin: 0 8px;
  }

  .nav-bar-userInfo {
    align-items: center;
    color: var(--color-white);
    display: flex;
    height: 40px;
    margin-left: 24px;

    > span {
      margin-left: 4px;
    }
  }
}

.nav-bar-right.is-horizontal {
  height: 36px;

  .badge-bell {
    margin: 0 8px;

    .el-icon-bell {
      color: var(--color-grey-7);
      font-size: 16px;
    }
  }

  .el-icon-service {
    color: var(--color-grey-7);
    font-size: 16px;
  }

  .nav-bar-userInfo {
    align-items: center;
    color: var(--color-grey-7);
    display: flex;
    height: 36px;
    margin-left: 24px;

    > span {
      margin-left: 4px;
    }
  }
}
</style>
