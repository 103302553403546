<template>
  <ul
    v-if="modelValue"
    class="menus"
    :style="{ top: position.top + 'px', left: position.left + 'px' }">
    <li
      v-for="(item,index) in menus"
      :key="index"
      @click="($event) => menusClick($event, item)">
      {{ item.label }}
    </li>
  </ul>
</template>

<script lang="ts">
import { onMounted, onUnmounted, defineComponent, PropType } from 'vue';
import { RightMenuItem } from '@/model/base/index';
export default defineComponent({
  name: 'RightMenus',
  props: {
    /** 双向绑定 */
    modelValue: Boolean,
    position: {
      type: Object,
      required: true
    },
    menus: {
      type: Array as PropType<RightMenuItem[]>,
      default: ():any[] => []
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const handleClose = () => emit('update:modelValue', false);
    onMounted(() => {
      document.addEventListener('click', handleClose);
    });
    onUnmounted(() => {
      document.removeEventListener('click', handleClose);
    });
    function menusClick (event:MouseEvent, item:any) {
      event.preventDefault();
      if (item && typeof item.click === 'function') {
        const val = item.click();
        if (val === false || val === null) {
          event.stopPropagation();
        }
      }
    }
    return {
      menusClick
    };
  }
});
</script>
<style lang="scss" scoped>
.menus {
  background-color: var(--color-white);
  border-radius: var(--border-radius-4);
  box-shadow: var(--box-shadow-medium);
  position: fixed;
  width: 120px;
  z-index: 1000;

  > li {
    border-bottom: 1px dashed var(--border-color);
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    color: var(--color-grey-7);
    line-height: 30px;
    list-style: none;
    text-align: center;
    width: 100%;
  }

  > li:hover {
    background-color: var(--color-blue-1);
  }

  > li:last-child {
    border: unset;
  }
}
</style>
