import { createStore } from 'vuex';
import { store as documents, DocumentsStore, State as DocumentsState } from '@/stores/modules/documents';
import { store as common, CommonStore, CommonState } from '@/stores/modules/common';

export type RootState = {
  documents: DocumentsState;
  common : CommonState;
};

export type Store = DocumentsStore<Pick<RootState, 'documents'>> & CommonStore<Pick<RootState, 'common'>>

export const store = createStore({
  modules: {
    documents,
    common
  }
});

export function useStore (): Store {
  return store as Store;
}
