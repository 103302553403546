/** 菜单+权限 */
// import ResourceService from '@/api/resource';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    currentKey: '',
    menuList: []
  }),
  actions: {   
  }
});
