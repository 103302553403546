import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';

export const GERP_UI = 'gerp_ui';
export const GERP_UI_SECRET = 'gerp_ui_secret';
export const AUTHORIZATION = `Basic ${Base64.encode(GERP_UI + ':' + GERP_UI_SECRET)}`;
export const AUTH_TENANT = 'gerp-auth-tenant';
export const AUTH_TOKEN = 'token';
export const AUTH_USER_ID = 'gerp-auth-user-id';
export const AUTH_TENANT_ID = 'gerp-auth-tenant-id';
export const X_AUTH_TOKEN = 'x-auth-token';

/** 设置token */
export function setToken (token: string) {
  Cookies.set(AUTH_TOKEN, token);
}
/** 移除token */
export function removeToken () {
  Cookies.remove(AUTH_TOKEN);
}
/** 获取token */
export function getToken () {
  return Cookies.get(AUTH_TOKEN);
}
/** 设置tenantId */
export function setTenantId (id: number) {
  Cookies.set(AUTH_TENANT_ID, id);
}
/** 移除设置tenantId */
export function removeTenantId () {
  Cookies.remove(AUTH_TENANT_ID);
}
/** 获取tenant-id */
export function getTenantId () {
  return Cookies.get(AUTH_TENANT_ID);
}
/** 设置x-auth-token */
export function setXToen (token: string) {
  Cookies.set(X_AUTH_TOKEN, token);
}
/** 移除设置x-auth-token */
export function removeXToen () {
  Cookies.remove(X_AUTH_TOKEN);
}
/** 获取x-auth-token */
export function getXToen () {
  return Cookies.get(X_AUTH_TOKEN);
}
