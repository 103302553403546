<template>
  <div class="left-menu copy-protection">
    <el-tree
      v-if="data.length > 0"
      :expand-on-click-node="false"
      :data="data"
      :current-node-key="currentKey"
      node-key="id"
      :accordion="data.length >= 5"
      :highlight-current="true"
      :default-expanded-keys="defaultExpandedKeys"
      :props="defaultProps"
      :default-expand-all="data.length < 5"
      class="menu-tree"
      @node-click="handleNodeClick"
    >
    <template #default="{ node, data }">
      <span class="custom-tree-node" v-if="node.label.length <7">{{ node.label }}</span>
      <el-tooltip
        v-else        
        effect="dark"
        :content="node.label"
        placement="top-start"
      >
      <span class="custom-tree-node">{{ node.label }}</span>
      </el-tooltip>
    </template>
  </el-tree>
    <gj-button class="logout-btn" @click="logout"> 退出登录 </gj-button>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import AuthService from "@/api/auth";
import { removeToken, removeXToen } from "@/utils/auth";
import { useAuthStore } from "@/store/modules/auth";
import { BaseService } from "@/api/base";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useAuthStore();

    // console.log(BaseService.userMenuList)
    const data = ref([]);
    const defaultExpandedKeys = ref([]);
    // 获取菜单
    const getMenuList = async () => {
      const res = BaseService.userMenuList;
      function traverse (arr: any[]) {
        arr.forEach((element) => {
          if (element?.route?.includes(route.path)) {
            defaultExpandedKeys.value = [element?.pid || element.id];
            // debugger
            store.currentKey = element.id;
          }
          if (element.children) {
            traverse(element.children);
          }
        });
      }
      traverse(res);
      data.value = res;
      // console.log(res);
      // console.log(route.path)
      // console.log(data.value)
    };

    // 退出登录
    const logout = async () => {
      await AuthService.logout();
      removeToken();
      removeXToen();
      router.push("/login");
    };

    const defaultProps = {
      children: "children",
      label: "name",
    };

    const handleNodeClick = (data: { typeStyle: string; route: string }) => {
      if (data.typeStyle !== "page") return false;
      let path = data.route.replace("/uac-app", "");
      path = path.replace("/amz-web", "");
      router.push(path);
    };

    onMounted(() => {
      getMenuList();
    });

    // 监听路由改变

    return {
      logout,
      defaultProps,
      handleNodeClick,
      defaultExpandedKeys,
      data,
      ...toRefs(store),
    };
  },
});
</script>
<style lang="scss" scoped>
.left-menu {
  padding: 16px 0 0 16px;
  .item {
    .icon-blcok {
      width: 23px;
      margin-left: 13px;
      .icon {
        width: 15px !important;
        height: 14px !important;
      }
      .icon-hover {
        display: none;
        width: 15px !important;
        height: 14px !important;
      }
      .icon-select {
        width: 15px !important;
        height: 14px !important;
      }
    }
    border-radius: 2px;
    font-weight: normal;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    &:hover {
      color: #1f6eef;
      background: #eef3fb;
      .icon {
        display: none;
      }
      .icon-hover {
        display: inline-block;
      }
    }
  }
  .item-select {
    border-left: 2px solid #1f6eef;
    color: #1f6eef;
    background: #eef3fb;
  }
}
.logout-btn {
  position: absolute;
  bottom: 40px;
  left: 60px;
}
.menu-tree {
  padding: 0 !important;
  width: 178px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  &::v-deep(.el-tree-node__content) {
    width: 150px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: inline-block;

    margin-bottom: 10px;
    .el-tree-node__label {
      font-size: 14px !important;
      font-weight: 600;
    }
  }
}
.custom-tree-node{
  font-size: 14px;  
}
</style>
