import { post, get, service, baseURL } from '../http';
import { LoginFormData, App } from '@/model/auth/index';
import { CustomResponse } from '@/model/base';
import { Tenant } from '@/model/tenant';

export default class AuthService {
  /** 用户名密码登录 */
  static login = (params: LoginFormData): Promise<CustomResponse<any>> => {
    return post<CustomResponse<any>>('/auth/login?origin=' + params.tenantCode, params)
      .then((res) => {
        return Promise.resolve(res);
      });
  };

  /** 登出   */
  static logout =(): Promise<any> => {
    return post<CustomResponse<any>>('/auth/logout')
      .then((res) => {
        return Promise.resolve(res.data);
      });
  };

  /** 用户首页-用户租户列表   http://{{apiUrl}}:7080/manager/user/index/tenants  */
  static getTenants = (): Promise<Tenant[]> => {
    return get<CustomResponse<Tenant[]>>('/manager/user/index/tenants')
      .then((res) => {
        return Promise.resolve(res.data);
      });
  };

  /** 用户首页-查询用户在某租户下的应用实例列表 */
  static getTenantApps = (tenantId: number): Promise<App[]> => {
    return get<CustomResponse<App[]>>(`manager/user/index/tenant/${tenantId}/app`)
      .then((res) => {
        return Promise.resolve(res.data);
      });
  };

  /** 获取ticket http://{{apiUrl}}:7080/auth/login/ticket */
  static getTicket = (webUrl: string): Promise<string> => {
    return post<CustomResponse<string>>('auth/login/ticket', { redirect: webUrl })
      .then((res) => {
        return Promise.resolve(res.data);
      });
  };

  /** 获取当前登录用户信息 */
  static getUserInfo = () :Promise<any> => {
    // return get<CustomResponse<any>>('/v2/system/account/current-username')
    //   .then((res) => Promise.resolve(res.data));
    return service.request<CustomResponse<any>>({
      url: '/v2/system/account/current-username',
      method: 'GET',
      baseURL: baseURL.replace('/api', '')
    }).then((res) => Promise.resolve(res.data.data));
  }

  static getMenuList = (): Promise<CustomResponse<any>> => {
    return get<CustomResponse<any>>('/manager/system/sys-resource/web/tree')
      .then((res) => {
        return Promise.resolve(res);
      });
  };
}
