<template>
  <div class="cron">
    <el-popover
      v-model:visible="state.cronPopover"
      width="550px"
      trigger="manual">
      <vue3-cron
        max-height="200px"
        @change="changeCron"
        @close="togglePopover(false)"></vue3-cron>
      <template #reference>
        <gj-input
          v-model="state.cron"
          :placeholder="modelplaceholder"
          :disabled="disabled"
          @focus="!disabled && togglePopover(true)"></gj-input>
      </template>
    </el-popover>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, watch } from 'vue';
import Vue3Cron from './widget/vue3-cron.vue';
export default defineComponent({
  name: 'GjCron',
  components: { Vue3Cron },
  props: {
    // 外部传入的内容，用于实现双向绑定
    modelValue: {
      type: String,
      required: true
    },
    modelplaceholder: {
      type: String,
      default: '* * * * * ? *'
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const state = reactive({
      cronPopover: false,
      cron: props.modelValue
    });
    const changeCron = (val: string) => {
      if (typeof (val) !== 'string') return false;
      state.cron = val;
    };
    const togglePopover = (bol: boolean) => {
      state.cronPopover = bol;
    };
    watch(() => state.cron, (newVal) => {
      emit('update:modelValue', newVal);
    });

    return {
      state,
      changeCron,
      togglePopover
    };
  }
});

</script>
