import { GetterTree } from 'vuex';
import { CommonState } from './state';
import { RootState } from '@/stores';
export type Getters = {
  getKeepAliveViews(state: CommonState): string[],
  getAppLayout(state: CommonState):string
}
export const getters: GetterTree<CommonState, RootState> & Getters = {
  getKeepAliveViews: state => state.keepAliveViews,
  getAppLayout: state => state.appLayout
};
