export interface MenusItem {
  id:string,
  icon?:string|null,
  path:string,
  label:string,
  name?:string, // 保持和路由一致
  close?:boolean,
  children?:MenusItem[],
}

/** 服务器菜单 本地伪造数据 */
export const menus: MenusItem[] = [
  {
    id: '1',
    icon: null,
    label: '产品',
    path: 'layout',
    children: [
      {
        id: '11',
        icon: 'product-manager',
        path: 'layout',
        label: '产品管理',
        children: [
          {
            id: '111',
            icon: null,
            label: '产品资料',
            name: 'productManager',
            path: '/product/product-manager'
          },
          {
            id: '112',
            icon: null,
            label: '品牌资料',
            name: 'productBrand',
            path: '/product/product-brand'
          }
        ]
      },
      {
        id: '12',
        icon: 'product-manager',
        path: 'layout',
        label: '广告',
        children: [
          {
            id: '121',
            icon: null,
            label: '广告管理',
            name: 'productManager',
            path: '/product/product-manager'
          },
          {
            id: '122',
            icon: null,
            label: '广告销售',
            name: 'productBrand',
            path: '/product/product-brand'
          }
        ]
      }
    ]
  },
  {
    id: '2',
    icon: null,
    label: '销售',
    path: 'layout',
    children: [
      {
        id: '21',
        icon: 'listing-manager',
        path: 'layout',
        label: '商品',
        children: [
          {
            id: '211',
            icon: null,
            label: 'listing',
            name: 'saleListing',
            path: '/sale/listing'
          },
          {
            id: '212',
            icon: null,
            label: 'Review',
            name: 'saleReview',
            path: '/sale/review'
          }
        ]
      }
    ]
  }
];
