<template>
  <div class="gj-upload">
    <div class="gj-upload-event-area">
      <input
        ref="uploadInputRef"
        class="gj-upload-input"
        :accept="accept"
        :multiple="multiple"
        type="file"
        @change="fileChange" />
      <div class="click-area" @click="onClickTrigger">
        <slot></slot>
      </div>
    </div>
    <div class="gj-upload-tips-area">
      <slot name="tip"></slot>
    </div>
    <ul :class="['gj-upload-file-list',mode === 'horizontal'? 'is-horizontal':'']">
      <li
        v-for="item in filesList"
        :key="item.uid"
        @click.self="handleFileClick(item)">
        <i class="el-icon-document"></i>
        {{ item.name }}
        <i v-if="item.status === 'success'" class="el-icon-circle-check"></i>
        <i v-if="item.status === 'fail'" class="el-icon-warning-outline"></i>
        <div class="delete-area" @click.stop="handleRemove(item)">
          <i class="el-icon-delete"></i>
        </div>
        <el-progress
          v-if="item.status ==='uploading'"
          :percentage="item.rate"
          :stroke-width="2"
          :show-text="false">
        </el-progress>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch, PropType } from 'vue';
import { ElMessage as message } from 'element-plus';
import { postformProgress } from '@/api/http';
import axios from 'axios';
import { UploadFile } from '@/model/base';
import { BaseService } from '@/api/base';

export default defineComponent({
  name: 'GjUpload',
  props: {
    accept: {
      type: String
    },
    limit: {
      type: Number
    },
    multiple: {
      type: Boolean
    },
    /** 上传附加的参数 模块标识和模块id */
    params: {
      type: Object,
      default: () => { }
    },
    actions: {
      type: String,
      default: '/v2/common/file/uploads'
    },
    mode: {
      type: String,
      default: 'vertical'
    },
    modelValue: {
      type: Array as PropType<UploadFile[]>,
      requird: true
    }
  },
  emits: ['update:modelValue', 'file-click'],
  setup (props, { emit }) {
    const filesList = ref<UploadFile[]>([]);
    const uploadInputRef = ref(null);
    const onClickTrigger = () => uploadInputRef.value.click();
    const fileChange = (e:Event) => {
      const files = (e.target as HTMLInputElement).files;
      if (!files) return;
      uploadFiles(files);
    };
    const uploadFiles = (files:FileList) => {
      if (props.limit && filesList.value.length + files.length > props.limit) {
        return message.warning('选择文件已经超出限制文件数');
      }
      let postFiles = Array.from(files);
      if (!props.multiple) {
        postFiles = postFiles.slice(0, 1);
      }
      if (postFiles.length === 0) {
        return;
      };
      postFiles.forEach(rawFile => {
        upload(rawFile);
      });
    };
    const upload = (rawFile:File) => {
      const timestamp = new Date().getTime();
      const fileItem:UploadFile = {
        name: rawFile.name,
        rate: 0,
        status: 'uploading',
        size: rawFile.size,
        uid: timestamp,
        raw: rawFile,
        source: axios.CancelToken.source()
      };
      filesList.value.push(fileItem);
      post(fileItem);
    };
    const post = (fileItem:UploadFile) => {
      const formData = new FormData();
      formData.append('file', fileItem.raw);
      const params = props.params || {};
      Object.keys(params).forEach(v => {
        formData.append(v, params[v]);
      });
      const index = filesList.value.findIndex(v => v.uid === fileItem.uid);
      postformProgress(props.actions, formData, e => {
        filesList.value[index].rate = (e.loaded / e.total * 100 | 0);
      }, fileItem.source.token).then(res => {
        filesList.value[index].id = res.data.data;
        filesList.value[index].response = res.data;
        filesList.value[index].status = 'success';
      }).catch(err => {
        console.log(err);
        filesList.value[index].status = 'fail';
      }).finally(() => {
        console.log('上传完成');
      });
    };
    watch(() => props.modelValue, (newVal) => {
      filesList.value = newVal;
    }, { immediate: true });
    watch(() => filesList.value, () => {
      emit('update:modelValue', filesList.value);
    }, { deep: true });
    const handleFileClick = (file:UploadFile) => {
      emit('file-click', file);
    };
    const handleRemove = async (file:UploadFile) => {
      const index = filesList.value.findIndex(v => v.uid === file.uid);
      if (file.status === 'uploading' && file.source) {
        // 上传中的删除 取消文件上传
        file.source.cancel();
      }
      // 删除服务器
      if (file.status === 'success' && file.id) {
        await BaseService.deleteFileById(file.id);
      }
      filesList.value.splice(index, 1);
    };
    return {
      filesList,
      fileChange,
      onClickTrigger,
      uploadInputRef,
      handleFileClick,
      handleRemove
    };
  }
});
</script>
<style lang="scss" scoped>
.gj-upload {
  .gj-upload-event-area {
    .gj-upload-input {
      display: none;
    }
  }
  .gj-upload-file-list{
    li{
      transition: all .5s cubic-bezier(.55,0,.1,1);
      font-size: 12px;
      color: var(--color-grey-8);
      line-height: 1.8;
      margin-top: 5px;
      position: relative;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;
      position: relative;
      padding-left: 20px;
      margin-right: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .el-icon-document{
        position: absolute;
        font-size: 14px;
        left:2px;
        top:4px;
      }
      .delete-area{
        width: 21px;
        height: 100%;
        line-height: 21px;
        top: 0;
        right: 0;
        display: none;
        font-size: 14px;
        background-color: #EEF3FB;
        text-align: center;
        position: absolute;
      }
      >[class^=el-icon-]{
        float: right;
        position: absolute;
        font-size: 14px;
        right:2px;
        top:4px;
      }
      >.el-icon-circle-check{
        color: var(--color-green-4);
      }
      >.el-icon-warning-outline{
        color: var(--color-red-4);
      }
    }
    li:hover{
      background-color: #EEF3FB;
      .delete-area{
        display: block;
      }
    }
  }
  .gj-upload-file-list.is-horizontal{
    display: flex;
    flex-wrap: wrap;
    li{
      margin-right: 8px;
    }
  }
}
</style>
