import has from './modules/hasPermission';
import copy from './modules/copy';
import lazyImage from './modules/lazy-image';
import type { App } from 'vue';

const directives = [
  has,
  copy,
  lazyImage
];

export default {
  install (app:App) {
    directives.forEach(v => {
      app.use(v);
    });
  }
};
