import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { BaseService } from '@/api/base';
import * as _ from 'lodash';
import { setXToen } from '@/utils/auth';
import { microAppNotice, MicroToMainEventType } from '@gj/micro-frontend';

import { isMicroApp } from '@/utils';
import { ElMessageBox } from 'element-plus';

const registerRoutes = () => {
  const modules = import.meta.globEager('./modules/*.ts');
  const routes = [];
  for (const val of Object.values(modules)) {
    routes.push(val.default);
  }
  return routes.flat();
};

// 获取code
const getAuthCode = (arr:any[] = []) => {
  let arrStr:string[] = [];
  arr.forEach(v => {
    if (v.code && v.code !== '') {
      arrStr.push(v.code);
    }
    if (v.childList && v.childList.length > 0) {
      arrStr = [...arrStr, ...getAuthCode(v.childList)];
    }
  });
  return arrStr;
};

/** 不做路由校验白名单 */
export const whiteList:string[] = ['/login', '/register', '/repassword'];

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/register.vue')
  },
  {
    path: '/repassword',
    name: 'register',
    component: () => import('@/views/pages/repassword.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/pages/redirect.vue')
  },
  {
    path: '/',
    redirect: isMicroApp() ? '/micro-app-dashboard' : '/tenant/dashboard'
  },
  {
    path: '/micro-app-dashboard',
    name: 'microAppDashboard',
    component: () => import('../views/modules/home/micro-app-dashboard.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/modules/home/dashboard/dashboard.vue')
  },
  {
    path: '/uac-app/',
    redirect: '/tenant/dashboard'
  },
  ...registerRoutes()
];

// console.log(routes);
const router = createRouter({
  history: createWebHistory('/uac-app/'),
  routes
});
const noTokenList:string[] = ['/login', '/uac-app/login', '/uac-app/micro-app-dashboard', '/micro-app-dashboard'];

router.beforeEach(async (to, form, next) => {
  const index = noTokenList.findIndex(item => {
    return to.fullPath.indexOf(item) > -1;
  });
  ElMessageBox.close();
  if (index > -1) {
    return next();
  }

  if (to.query.authToken) {
    // if (import.meta.env.MODE === 'development') {
    //   // 请修改 .env 文件的 VITE_TOKEN 变量
    //   setXToen(`${import.meta.env.VITE_TOKEN}`);
    // } else {
    //   setXToen(to.query.authToken as string);
    // }
    setXToen(to.query.authToken as string);
  }
  // debugger;
  if (_.isEmpty(BaseService.userRoles)) {
    const res = await BaseService.getRoles();
    BaseService.userRoles = res || [];
  }

  if (_.isEmpty(BaseService.userMenuList)) {
    const res = await BaseService.getmenuList();
    BaseService.userMenuList = res || [];
  }

  // debugger
  if (to.matched.length === 0) microAppNotice.emit(MicroToMainEventType.PAGE_404, {});

  next();
});

export default router;
