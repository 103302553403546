import { MainToMicroEventType, useMicroAppNotice } from '@gj/micro-frontend';
import { useCommonStore } from '@/store/modules/common';
import { LocationQuery, RouteLocationNormalized, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { isMicroApp } from "@/utils";

function deleteRoute (data: any) {
  // const router = useRouter();
  // console.log(data);
  // 全局通用数据中心
  const commonStore = useCommonStore();
  // keepAlive
  const keepAliveViews = commonStore.keepAliveViews;
  // @ts-ignore
  const index = keepAliveViews.map(view => view.path).indexOf(data.route);
  if (index > -1) {
    keepAliveViews.splice(index, 1);
    commonStore.setKeepAliveViews(keepAliveViews);
    // if (data.routeToHome) {
    //   setTimeout(() => router.push('/'));
    // }
  }
}

export function useIframeListener () {
  const { registerListener, sendRouterChange } = useMicroAppNotice();
  const router = useRouter();
  const route = router.currentRoute.value;
  const { locale } = useI18n();

  registerListener(MainToMicroEventType.CLICK, event => {
    document.dispatchEvent(new MouseEvent('mousedown'));
    document.dispatchEvent(new MouseEvent('mouseup'));
    document.dispatchEvent(new PointerEvent('pointerdown'));
    document.body.click();
  });

  registerListener(MainToMicroEventType.ROUTER_CHANGE, event => {
    const { route: url, queryParams } = event.data;

    if (route.path !== url || identityObject(route.query, queryParams)) {
      router.push({
        path: url,
        query: queryParams
      });
    }
  });

  registerListener(MainToMicroEventType.CLOSE_PAGE, event => {
    deleteRoute(event.data);

    if (!event.data.to) {
      router.push('/').then();
    }
  });

  registerListener(MainToMicroEventType.LANGUAGE, event => {
    locale.value = event.data;
  });

  registerListener(MainToMicroEventType.CLEAR_POPUP, event => {
    document.dispatchEvent(new MouseEvent('mousedown'));
    document.dispatchEvent(new MouseEvent('mouseup'));
  });

  router.afterEach((to: RouteLocationNormalized) => {
    if (['/', '/dashboard'].includes(to.path)) {
      return;
    }

    if (isMicroApp) {
      // TODO 管理中心
      // sendRouterChange({
      //   route: to.path,
      //   title: to.meta.label,
      //   queryParams: to.query,
      //   key: to.meta.label
      // });
    }
  });

  /*registerListener(event => {
    console.log(event, '基座应用');
    switch (event.type) {
      case MainToMicroEventType.CUSTOMIZE:
        break;
      case MainToMicroEventType.BROADCAST:
        break;
      case MainToMicroEventType.ROUTER_CHANGE:
        // eslint-disable-next-line no-case-declarations
        const { route: url, queryParams } = event.data;

        if (route.path !== url || identityObject(route.query, queryParams)) {
          router.push({
            path: url,
            query: queryParams
          });
        }
        break;
      case MainToMicroEventType.CLOSE_PAGE:
        deleteRoute(event.data);
        break;
      case MainToMicroEventType.LANGUAGE:
        locale.value = event.data;
        break;
      case MainToMicroEventType.CLEAR_POPUP:
        // 嵌入到angular应用时，如果存在下拉框展开的情形，会导致路由跳转时，下拉框不收起的问题
        // 查看element代码后发现element会监听如下事件进行下拉框收起动作
        document.dispatchEvent(new MouseEvent('mousedown'));
        document.dispatchEvent(new MouseEvent('mouseup'));
        break;
      case MainToMicroEventType.CLOSE_ALL_PAGE:
        break;
    }
  });*/
}

function identityObject (obj1: LocationQuery, obj2: Record<string, string>): boolean {
  if (obj1 === null && obj2 === null) {
    return true;
  } else if (obj1 !== null && obj2 !== null) {
    return obj1.toString() === obj2.toString();
  } else {
    return false;
  }
}
