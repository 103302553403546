
<template>
  <div class="settings-columns">
    <gj-alert
      type="info"
      border>
      勾选您想要在当前页面展示的列，以后将按照您的勾选默认展示。
    </gj-alert>
    <div class="settings-columns-left">
      <gj-scrollbar type="mini">
        <el-checkbox-group v-model="checkColumns">
          <ul class="column-list">
            <li
              v-for="item in sortOnlineColumns"
              :key="item.field"
              class="column-list-item">
              <gj-checkbox
                :label="item.field"
                :disabled="item.disabled">
                {{ item.title }}
              </gj-checkbox>
            </li>
          </ul>
        </el-checkbox-group>
      </gj-scrollbar>
    </div>
  </div>
  <div class="settings-colomns-footer">
    <gj-button @click="$emit('cancel')">
      取消
    </gj-button>
    <gj-button :loading="loading && type === 'default'" @click="recoverDefaultSetting">
      恢复默认设置
    </gj-button>
    <gj-button
      :loading="loading && type === 'change'"
      type="primary"
      @click="handleConfirm">
      确认
    </gj-button>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType, onMounted } from 'vue';
import { ColumnItem } from '@/types/table';
export default defineComponent({
  props: {
    sortOnlineColumns: {
      type: Array as PropType<ColumnItem[]>,
      default: ():[] => [],
      required: true
    }
  },
  emits: ['cancel', 'change-columns'],
  setup (props, { emit }) {
    const checkColumns = ref<string[]>([]);
    const loading = ref(false);
    const type = ref('');
    onMounted(() => {
      checkColumns.value = props.sortOnlineColumns.filter(v => v.checked).map(v => v.field);
    });
    const handleConfirm = () => {
      loading.value = true;
      type.value = 'change';
      emit('change-columns', { type: 'change', config: checkColumns.value });
    };
    // 恢复默认
    const recoverDefaultSetting = () => {
      loading.value = true;
      type.value = 'default';
      emit('change-columns', { type: 'default' });
    };
    return {
      checkColumns,
      handleConfirm,
      recoverDefaultSetting,
      loading,
      type
    };
  }
});
</script>

<style lang="scss">
.sortable-chosen {
  background-color: var(--color-bg-1);
  border: var(--border-base);
  border-radius: var(--border-radius-2);
}

.settings-columns {
  max-height: 400px;
  .gj-alert{
    padding: 8px 16px;
  }
  .settings-columns-left {
    padding: 10px 10px 0;
    .column-list {
      display: flex;
      flex-wrap: wrap;

      .column-list-item {
        padding: 5px 0;
        width: calc(100% / 3);
        .el-checkbox.el-checkbox--medium{
          height: 21px;
          line-height: 21px;
        }
      }
    }
  }
}

.settings-colomns-footer {
  align-items: center;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-grey-4);
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
</style>
