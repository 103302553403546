<template>
  <div class="app-base-main">
    <div
      v-if="$slots.left && !isMicroApp"
      class="app-base-main-left"
      :style="{width:leftWidth+'px'}">
      <Menu></Menu>
    </div>
    <div :class="isMicroApp ? 'app-base-main-right' : 'app-base-main-right'">
      <slot></slot>
      <div class="table-box">
        <slot name="table"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isMicroApp } from '@/utils';
import Menu from '../menu/index.vue';

export default defineComponent({
  name: 'AppBaseMain',
  components: { Menu },
  props: {
    /** 左边的宽度 */
    leftWidth: {
      type: Number,
      default: 200
    }
  },
  setup () {

    return {
      isMicroApp: isMicroApp()
    };
  }
});

</script>

<style lang="scss" scoped>
.app-base-main {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 10px 16px 16px;
  /*padding-top: 10px;*/

  .app-base-main-left {
    background-color: var(--color-white);
    border-radius: var(--border-radius-4);
    border-right: 1px solid #e4e5e6;
    //margin-right: 1px;
  }

  .app-base-main-right {
    background-color: var(--color-white);
    border-radius: var(--border-radius-4);
    display: flex;
    flex: 1;
    // flex: auto;
    flex-direction: column;
    overflow: auto;
    padding: 20px;

    .table-box {
      flex-grow: 1;
      overflow: hidden;
    }
  }

  .app-base-main-right::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .app-base-main-right::-webkit-scrollbar-track {
    background: var(--color-grey-2);
    border-radius: 2px;
  }

  .app-base-main-right::-webkit-scrollbar-thumb {
    background-color: var(--color-grey-4);
    border-radius: var(--border-radius-4);
    cursor: pointer;
  }
}
</style>
