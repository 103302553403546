import gplus from '@gj/gplus-ui';
import type { App } from 'vue';

import GjUpload from './upload';
import AppBaseMain from './app-base-main';
import OperateBar from './operate-bar';
import SearchBar from './search-bar';
import GjLogTable from './log-table';
import GjRichTextEditor from './rich-text-editor';
import GjAceEditor from './ace-editor';
import GjCron from './cron';
const components = [
  GjUpload,
  AppBaseMain,
  OperateBar,
  SearchBar,
  GjLogTable,
  GjRichTextEditor,
  GjAceEditor,
  GjCron
];
export default (app:App) => {
  app.use(gplus, { modalTo: ['.app-base-main', '.gj_layout_base'] });
  components.forEach(component => {
    app.use(component.install);
  });
};
