import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/stores';
import { CommonState } from './state';
import { Mutations } from './mutations';
import { CommonMutationTypes } from './mutation-types';
import { CommonActionTypes } from './action-types';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CommonState, RootState>, 'commit'>

export interface Actions {
  [CommonActionTypes.GET_APP_LAYOUT]({ commit }: AugmentedActionContext):void,
  [CommonActionTypes.GET_KEEP_ALIVE_VIEWS]({ commit }:AugmentedActionContext, keepAliveViews:string[]):void
}

export const actions: ActionTree<CommonState, RootState> & Actions = {
  [CommonActionTypes.GET_APP_LAYOUT] ({ commit }) {
    const mode:string = localStorage.appLayout ? localStorage.appLayout : 'vertical';
    commit(CommonMutationTypes.SET_APP_LAYOUT, mode);
  },
  [CommonActionTypes.GET_KEEP_ALIVE_VIEWS] ({ commit }, keepAliveViews:string[]) {
    commit(CommonMutationTypes.SET_KEEP_ALIVE_VIEWS, keepAliveViews);
  }
};
