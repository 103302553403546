import { post, get, del, service} from '../http';
import { CustomResponse } from '@/model/base';
import trim from 'lodash/trim';
import { ElMessage } from 'element-plus';
const message = (str: string) => ElMessage({
  message: str,
  type: 'error',
  duration: 3 * 1000
});
export class BaseService {
  static userRoles: string[];
  static userMenuList: string[];
  static userRolesStr:string[] = []

  /** 获取国家 */
  static getCountries = () => get('/base/market/countries');
  /** 获取站点 */
  static getMarkets = () => post('/base/market/markets', {});
  /** 获取授权国家 */
  static getAuthCountries = () => get('/base/market/authCountries');
  /** 获取授权站点 */
  static getAuthMarkets = () => post('/base/market/authMarkets', {});
  /** 删除文件 */
  static removeAttachmentById = (fileId:string) => del(`/file/attachment?ids[]=${fileId}`);
  /** 获取资源权限 */
  static getRoles = (): Promise<string[]> => get<CustomResponse<string[]>>('/manager/system/sys-resource/get/buttonList').then(c => c.data);
  /** 获取菜单 */
  static getmenuList = (): Promise<string[]> => get<CustomResponse<string[]>>('/manager/system/sys-resource/web/tree').then(c => c.data);
  /** 获取自定义列 */
  static getColumnsConfig = (module:string) => get<CustomResponse<string>>(`/v2/setting/custom?module=${module}`)
  /** 获取自定义列 */
  static setColumnsConfig = (params:{module:string, columns:string}) => service.put<CustomResponse<unknown>>('/v2/setting/custom', params)
  /** 上传图片 */
  static uploadFile = (params:Object): Promise<CustomResponse<any[]>> => {
    return post<CustomResponse<any>>('/v2/file/uploadFile', params)
      .then((res) => {
        return Promise.resolve(res); 
      }); 
  };
  /** 导出 */
  static download = (url: string, params: any) => service.request({
    method: 'post',
    url: url,
    data: params,
    responseType: 'blob'
  }).then(res => downloadFile(res));

    /** 导出 get请求 */
    static downloadResource = (url: string, params: any) => service.request({
      method: 'get',
      url: url,
      data: params,
      responseType: 'blob'
    }).then(res => downloadFile(res));
   

  /** 删除文件 */
  static deleteFileById = (id:string) => del<CustomResponse<unknown>>(`/v2/common/attachment/${id}`)
}

async function downloadFile(resp: any, customFileName = '') { 
  const data = resp.data;  

  if(data.type === "application/json"){
    const text = await new Blob([data]).text(); 
    if(JSON.parse(text).code === 1){
      message(JSON.parse(text).msg)
      return
    }    
  }
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  // 文件名在后端设置
  let fileName = resp.headers.filename || customFileName;
  if (!fileName) {
    try {
      fileName = trim(
        resp.headers['content-disposition'].split(';')[1].split('=')[1],
        '"'
      );
    } catch (err) {}
  }
  link.setAttribute('download', decodeURI(fileName));
  document.body.appendChild(link);
  link.click();
}
