export default [
  {
    path: '/integrate-config/platforms',
    name: 'IntegrateConfigPlatform',
    component: () => import('@/views/modules/integrate-config/platform/index.vue'),
    meta: { label: '平台管理', keepAlive: true }
  },
  {
    path: '/integrate-config/platform-account',
    name: 'IntegrateConfigPlatformAccount',
    component: () => import('@/views/modules/integrate-config/platform-account/index.vue'),
    meta: { label: '租户平台账号管理', keepAlive: true }
  },
  {
    path: '/integrate-config/adapter',
    name: 'IntegrateConfigAdapter',
    component: () => import('@/views/modules/integrate-config/adapter/index.vue'),
    meta: { label: '适配器管理', keepAlive: true }
  },
  {
    path: '/integrate-config/api-classify',
    name: 'IntegrateConfigApiClassify',
    component: () => import('@/views/modules/integrate-config/api-classify/index.vue'),
    meta: { label: 'API资产分类管理', keepAlive: true }
  },
  {
    path: '/integrate-config/api-property',
    name: 'IntegrateConfigApiProperty',
    component: () => import('@/views/modules/integrate-config/api-property/index.vue'),
    meta: { label: 'API资产管理', keepAlive: true }
  },
  {
    path: '/integrate-config/param-config',
    name: 'IntegrateConfigParamConfig',
    component: () => import('@/views/modules/integrate-config/param-config/index.vue'),
    meta: { label: 'API资产参数配置', keepAlive: true }
  },
  {
    path: '/integrate-config/task',
    name: 'IntegrateConfigTask',
    component: () => import('@/views/modules/integrate-config/task/index.vue'),
    meta: { label: '任务管理', keepAlive: true }
  },
  {
    path: '/integrate-config/tactics',
    name: 'IntegrateConfigTactics',
    component: () => import('@/views/modules/integrate-config/tactics/index.vue'),
    meta: { label: '策略管理', keepAlive: true }
  },
  {
    path: '/integrate-config/scheme',
    name: 'IntegrateConfigScheme',
    component: () => import('@/views/modules/integrate-config/scheme/index.vue'),
    meta: { label: '集成方案管理', keepAlive: true }
  },
  {
    path: '/integrate-config/call-log',
    name: 'IntegrateConfigCallLog',
    component: () => import('@/views/modules/integrate-config/call-log/index.vue'),
    meta: { label: '调用日志', keepAlive: true }
  }
];
