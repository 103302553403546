import { isMicroApp } from '@/utils';

export function microPlugin (): void {
  const isMicro = isMicroApp();

  if (!isMicro) {
    return;
  }

  if (!document.body.classList.contains('micro-app')) {
    document.body.classList.add('micro-app');
  }
}
