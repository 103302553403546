<template>
  <div id="app">
    <template v-if="pages">
      <router-view></router-view>
    </template>
    <template v-else>
      <el-config-provider :locale="lang[locale]">
        <app-layout></app-layout>
      </el-config-provider>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElConfigProvider } from 'element-plus';
// import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { zh as zhCn, en } from '@gj/gplus-ui';
import appLayout from './layout/layout.vue';
import { whiteList } from '@/router';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import {
  MicroAppType,
  MicroToMainEventType,
  useMicroAppListener,
  useMicroAppNotice,
  useMicroAppProvide,
  useMicroBuriedPoint,
  useMicroAppInterceptorProvider
} from '@gj/micro-frontend';
import { useIframeListener } from '@/plugins/use-iframe-listener';

zhCn.el.pagination.goto = '跳至';
zhCn.el.pagination.pagesize = '';
useMicroAppProvide();
const { registerMicroApp } = useMicroAppNotice();
registerMicroApp(MicroAppType.UAC);
useMicroBuriedPoint();
useIframeListener();
if (window !== window.parent) useMicroAppInterceptorProvider();
const { locale } = useI18n();

// const {registerListener} = useMicroAppNotice();
// const router = useRouter();

// registerListener(MainToMicroEventType.ROUTER_CHANGE, event => {
//   console.log(event, 'fghjkdh');
//   const {data: {route}} = event;
//   router.push({
//     path: route
//     // query: queryParams
//   });
// });

useMicroAppListener((type, data) => {
  const {sendUnauthorized, sendInfoPage, send404Page, sendRedirect, sendReauthorized} = useMicroAppNotice(); 
  switch (type) {
    case MicroToMainEventType.START_LOAD:
      break;
    case MicroToMainEventType.LOADED:
      break;
    case MicroToMainEventType.CUSTOMIZE:
      break;
    case MicroToMainEventType.BROADCAST:
      break;
    case MicroToMainEventType.UNAUTHORIZED:
      sendUnauthorized();
      break;
    case MicroToMainEventType.CLOSE_PAGE:
      break;
    case MicroToMainEventType.OPEN_PAGE:
      sendInfoPage(data);
      break;
    case MicroToMainEventType.PAGE_404:
      send404Page();
      break;
    case MicroToMainEventType.MESSAGE:
      break;
    case MicroToMainEventType.REDIRECT:
      console.log('MicroToMainEventType.REDIRECT')
      sendRedirect(data);
      break;
    case MicroToMainEventType.REAUTHORIZED:
      sendReauthorized(data);
      break;
  }
});

const route = useRoute();
const pages = computed(() => whiteList.includes(route.path));
// 监听国际化语言变化
// const { locale } = useI18n();
// window.addEventListener('message', function ({ data: { type, data } }) {
//   if (type === 'changeLocale') {
//     locale.value = data.locale;
//   }
// });

const lang = {
  en,
  zh: zhCn
};
</script>
